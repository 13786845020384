import { productsConstant } from "../_constants";

export function products(state = {}, action) {
    switch (action.type) {
        case productsConstant.GETALL_REQUEST:
            return {
                loading: true,
            };
        case productsConstant.GETALL_SUCCESS:
            return {
                loading: false,
                items: action.products.data,
                current_page: action.products.current_page,
                first_page_url: action.products.first_page_url,
                
            };
        case productsConstant.GETALL_FAILURE:
            return {
                loading: false,
                error: action.error
            }
        default: 
            return state
    }
}

export function selectedProducts(state = {}, action) {
    switch(action.type) {
        case productsConstant.GETBYID_REQUEST:
            return {
                loading: true
            };
        case productsConstant.GETBYID_SUCCESS:
            return {
                loading: false,
                items: action.selected,
            };
        case productsConstant.GETBYID_FAILURE:
            return {
                loading: false,
                error: action.error
            }
        default: 
            return state
    }
}

export function lesson(state = {}, action) {
    switch (action.type) {
        case productsConstant.GETLESSON_REQUEST:
            return {
                loading: true,
                contents: [],
                product: {}
            };
        case productsConstant.GETLESSON_SUCCESS:
            return {
                loading:false,
                product: action.lesson,
                contents: action.lesson.contents,
                idProduct: action.lesson.id
            }
        case productsConstant.GETLESSON_FAILURE:
            return {
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function content(state = {}, action) {
    switch (action.type) {
        case productsConstant.GETCONTENT_REQUEST:
            return {
                loading: true
            };
        case productsConstant.GETCONTENT_SUCCESS:
            return {
                loading:false,
                items: action.content
            }
        case productsConstant.GETCONTENT_FAILURE:
            return {
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}
