import React from "react";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  CircularProgress,
  Paper,
  Hidden,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  productCard: {
    height: "100%",
  },
  paperHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  image: {
    width: "100%",
    height: "100%",
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));

export function ProductDetailHeader({
  productTitle,
  registrationStart,
  registrationEnd,
  productImg,
}) {
  const classes = useStyles();

  const eventRegistStart = new Intl.DateTimeFormat("id-ID", {
    day: "numeric",
    month: "long",
  }).format(new Date(registrationStart));

  const eventRegistEnd = new Intl.DateTimeFormat("id-ID", {
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(new Date(registrationEnd));

  return (
    <>
      <Container maxWidth disableGutters style={{ marginBottom: "2rem" }}>
        <Paper variant="outlined" className={classes.paperHeader}>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} md={4}>
              <Hidden smDown>
                <Box className={classes.image}>
                  <img
                    className={classes.img}
                    alt="training"
                    src={
                      productImg
                        ? productImg
                        : `https://via.placeholder.com/150`
                    }
                  />
                </Box>
              </Hidden>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md
              alignItems="center"
              justifyContent="center"
            >
              <Hidden smDown>
                <Typography variant="h3">
                  <Box fontWeight="medium" mdUp>
                    {productTitle}
                  </Box>
                </Typography>
              </Hidden>
              <Hidden mdUp>
                <Typography variant="h4">
                  <Box fontWeight="medium" mdUp>
                    {productTitle}
                  </Box>
                </Typography>
              </Hidden>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md
              alignItems="center"
              justifyContent="center"
            >
              <Paper variant="outlined">
                <Box p={2}>
                  <Typography variant="body1" color="textSecondary">
                    Registrasi
                  </Typography>
                  <Typography variant="h6">
                    <Box>
                      {eventRegistStart} - {eventRegistEnd}
                    </Box>
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
}
