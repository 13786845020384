export const productsConstants = {
  GET_LIST_REQUEST: "PRODUCT_GET_LIST_REQUEST",
  GET_LIST_SUCCESS: "PRODUCT_GET_LIST_SUCCESS",
  GET_LIST_FAILED: "PRODUCT_GET_LIST_FAILED",

  GET_DETAIL_REQUEST: "PRODUCT_GET_DETAIL_REQUEST",
  GET_DETAIL_SUCCESS: "PRODUCT_GET_DETAIL_SUCCESS",
  GET_DETAIL_FAILED: "PRODUCT_GET_DETAIL_FAILED",

  CLEAR_DETAIL: "PRODUCT_CLEAR_DETAIL"
};
