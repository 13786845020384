import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Box,
  Button,
  Divider,
  Container,
  CircularProgress,
  Paper,
  Hidden,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { history } from "../../_helpers";
import { differenceInDays } from "date-fns";
import AccessTimeRoundedIcon from "@material-ui/icons/AccessTimeRounded";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import RoomRoundedIcon from "@material-ui/icons/RoomRounded";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import CalendarTodayRoundedIcon from "@material-ui/icons/CalendarTodayRounded";

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    height: "100%",
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  paperHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  paperBody: {
    padding: theme.spacing(2),
  },
  priceBox: {
    width: "40rem",
  },
}));

export const ProductDetail = ({ className, ...rest }) => {
  const classes = useStyles();
  const isLoading = useSelector((state) => state.products.loading);
  const selectedProd = useSelector((state) => state.selectedProducts.items);
  const user = useSelector((state) => state.user);
  const transaction = useSelector((state) => state.transaction);
  let paidTransaction;
  if (transaction.items) {
    let trxArray = transaction.items;
    trxArray.forEach((trx) => {
      if (
        trx.transaction_status === "PAID" &&
        trx.product_id === selectedProd.id
      ) {
        // console.log(trx)
        paidTransaction = trx;
      }
    });
  }
  const dateOnly = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };

  // const timeOnly = {
  //   hour: "numeric",
  //   minute: "numeric",
  // };

  const currencyOption = {
    style: "currency",
    currency: "IDR",
    maximumSignificantDigits: 6,
  };

  const eventStart = new Intl.DateTimeFormat("id-ID", {
    day: "numeric",
    month: "long",
  }).format(new Date(selectedProd.event_start));

  const eventEnd = new Intl.DateTimeFormat("id-ID", dateOnly).format(
    new Date(selectedProd.event_end)
  );

  const eventRegistStart = new Intl.DateTimeFormat("id-ID", {
    day: "numeric",
    month: "long",
  }).format(new Date(selectedProd.registration_start));

  const eventRegistEnd = new Intl.DateTimeFormat("id-ID", {
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(new Date(selectedProd.registration_end));

  const eventDays = differenceInDays(
    new Date(selectedProd.event_end),
    new Date(selectedProd.event_start)
  );
  const eventPrice = new Intl.NumberFormat("id-ID", currencyOption).format(
    selectedProd.event_price
  );

  function handleGetProduct(id) {
    history.push("/checkout/" + id);
  }
  function handleGetLesson(id) {
    history.push("/products/" + id + "/lesson");
  }

  function backToLogin() {
    history.push("/login");
  }
  return (
    <React.Fragment>
      {isLoading ? (
        <span
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress align="center" />
        </span>
      ) : (
        <React.Fragment>
          <Container maxWidth disableGutters style={{ marginBottom: "2rem" }}>
            <Paper variant="outlined" className={classes.paperHeader}>
              <Grid container direction="row" spacing={3}>
                <Grid item xs={12} md={4}>
                  <Hidden smDown>
                    <Box className={classes.image}>
                      <img
                        className={classes.img}
                        alt="training"
                        src="https://via.placeholder.com/150"
                      />
                    </Box>
                  </Hidden>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  md
                  alignItems="center"
                  justifyContent="center"
                >
                  <Hidden smDown>
                    <Typography variant="h3">
                      <Box fontWeight="medium" mdUp>
                        {selectedProd.name}
                      </Box>
                    </Typography>
                  </Hidden>
                  <Hidden mdUp>
                    <Typography variant="h4">
                      <Box fontWeight="medium" mdUp>
                        {selectedProd.name}
                      </Box>
                    </Typography>
                  </Hidden>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  md
                  alignItems="center"
                  justifyContent="center"
                >
                  <Paper variant="outlined">
                    <Box p={2}>
                      <Typography variant="body1" color="textSecondary">
                        Registrasi
                      </Typography>
                      <Typography variant="h6">
                        <Box>
                          {eventRegistStart} - {eventRegistEnd}
                        </Box>
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Container>
          {/* Container header ends  */}

          {/* Container body */}
          <Container>
            {/* First Row */}
            <Grid container direction="row" spacing={2}>
              <Grid item sm={12} md={6}>
                <Hidden smDown>
                  <Typography variant="h4">Description</Typography>
                  <Divider style={{ marginBottom: "1rem" }} />
                </Hidden>
                <Hidden mdUp>
                  <Typography variant="h5">Description</Typography>
                  <Divider style={{ marginBottom: "1rem" }} />
                </Hidden>
                <Typography variant="body1" paragraph gutterBottom>
                  {selectedProd.event_description}
                </Typography>
              </Grid>
              {/* First Row End */}

              {/* Second Row */}
              <Grid item sm={12} md={6}>
                <Hidden smDown>
                  <Typography variant="h4">Trainer</Typography>
                  <Divider style={{ marginBottom: "1rem" }} />
                </Hidden>
                <Hidden mdUp>
                  <Typography variant="h5">Trainer</Typography>
                  <Divider style={{ marginBottom: "1rem" }} />
                </Hidden>

                <Typography variant="body1" paragraph gutterBottom>
                  {typeof selectedProd.trainer_id !== "string"
                    ? selectedProd.trainer_id.map((contents) => {
                        return <div>- {contents.name}</div>;
                      })
                    : ""}
                  {/* <li>{ selectedProd.trainer.name}</li> */}
                </Typography>
              </Grid>
              {/* Second Row End */}

              {/* Third Row */}
              <Grid item xs={12} md={6} alignItems="stretch">
                <Hidden smDown>
                  <Typography variant="h4">Materi</Typography>
                  <Divider style={{ marginBottom: "1rem" }} />
                </Hidden>
                <Hidden mdUp>
                  <Typography variant="h5">Materi</Typography>
                  <Divider style={{ marginBottom: "1rem" }} />
                </Hidden>

                <Typography variant="body1" paragraph gutterBottom>
                  {selectedProd.contents.map((contents) => {
                    return <div>- {contents.title}</div>;
                  })}
                </Typography>

                <Paper style={{ padding: "0.5rem" }} elevation={4}>
                  <Grid container direction="column" xs={12} spacing={1}>
                    <Grid item container direction="row" alignItems="center">
                      <Grid item md={2} xs={2} container justifyContent="center">
                        <Box color="cyan">
                          <CalendarTodayRoundedIcon
                            color="primary"
                            fontSize="large"
                          />
                        </Box>
                      </Grid>
                      <Grid item md={4} xs={5}>
                        <Box fontWeight="bold">
                          <Typography variant="h6">
                            Tanggal Pelatihan :{" "}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={6} xs={5}>
                        <Typography variant="subtitle1">
                          {eventStart} - {eventEnd}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item container direction="row" alignItems="center">
                      <Grid item md={2} xs={2} container justifyContent="center">
                        <Box color="cyan">
                          <AccessTimeRoundedIcon
                            color="primary"
                            fontSize="large"
                          />
                        </Box>
                      </Grid>
                      <Grid item md={4} xs={5}>
                        <Box fontWeight="bold">
                          <Typography variant="h6">
                            Durasi Pelatihan :{" "}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={6} xs={5}>
                        <Typography variant="subtitle1">
                          {eventDays} Hari
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item container direction="row" alignItems="center">
                      <Grid item xs={2} container justifyContent="center">
                        <Box color="cyan">
                          <MenuBookRoundedIcon
                            color="primary"
                            fontSize="large"
                          />
                        </Box>
                      </Grid>
                      <Grid item md={4} xs={5}>
                        <Box fontWeight="bold">
                          <Typography variant="h6">Materi :</Typography>
                        </Box>
                      </Grid>
                      <Grid item md={6} xs={5}>
                        <Typography variant="subtitle1">
                          {selectedProd.countLesson} TBD
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item container direction="row" alignItems="center">
                      <Grid item xs={2} container justifyContent="center">
                        <Box color="cyan">
                          <RoomRoundedIcon color="primary" fontSize="large" />
                        </Box>
                      </Grid>
                      <Grid item md={4} xs={5}>
                        <Box fontWeight="bold">
                          <Typography variant="h6">Lokasi :</Typography>
                        </Box>
                      </Grid>
                      <Grid item md={6} xs={5}>
                        <Typography variant="subtitle1">
                          {selectedProd.event_address}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              {/* Third row end         */}

              {/* Fourth row */}
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  {/* Grid for card box */}
                  <Grid
                    item
                    xs={12}
                    md={6}
                    container
                    justifyContent="center"
                    alignItems="flex-end"
                  >
                    {/* Card box container */}
                    <Box
                      border={1}
                      borderColor="primary.main"
                      className={classes.priceBox}
                    >
                      {/* Card box grid row 1 */}
                      <Grid item xs={12}>
                        <Box
                          bgcolor="#413c69"
                          borderColor="primary.main"
                          border={1}
                          height="5rem"
                          display="flex"
                          justifyContentContent="center"
                          alignItems="center"
                          color="grey.200"
                          borderBottom={0}
                        >
                          <Box textAlign="center">
                            <Typography variant="body2" bold>
                              <Box fontWeight="fontWeightLight">Best Price</Box>
                            </Typography>
                            <Typography variant="h4" bold>
                              {eventPrice}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      {/* Card box grid row 1 end*/}

                      {/* Card box grid row 2*/}
                      <Grid item xs={12}>
                        <PromoText />
                      </Grid>
                      {/* Card box grid row 2 end*/}
                      {/* Card box grid row 3*/}
                      <Grid item xs={12}>
                        <Box
                          height="100%"
                          alignItem="center"
                          justifyContentContent="center"
                          mt={1}
                          p={1}
                        >
                          {localStorage.getItem("user") && !paidTransaction ? (
                            <Button
                              variant="contained"
                              onClick={() => handleGetProduct(selectedProd.id)}
                              to="/checkout"
                              color="primary"
                              fullWidth
                            >
                              <Typography variant="body1">Join Now</Typography>
                            </Button>
                          ) : (
                            ""
                          )}
                          {user && paidTransaction ? (
                            <Button
                              variant="contained"
                              onClick={() => handleGetLesson(selectedProd.id)}
                              // to="/checkout"
                              color="primary"
                              fullWidth
                            >
                              <Typography variant="body1">
                                Go To Lesson
                              </Typography>
                            </Button>
                          ) : (
                            ""
                          )}
                          {user.error ? (
                            <div>
                              <Button
                                variant="contained"
                                color="primary"
                                to="/login"
                                onClick={() => backToLogin()}
                                style={{ minWidth: "3rem" }}
                                fullWidth
                              >
                                <Typography variant="body1">login</Typography>
                              </Button>

                              <Box
                                textAlign="center"
                                fontStyle="oblique"
                                mt={1}
                              >
                                <Typography style={{ fontSize: "0.8rem" }}>
                                  You need login to join this course
                                </Typography>
                              </Box>
                            </div>
                          ) : (
                            ""
                          )}
                        </Box>
                      </Grid>
                      {/* Card box grid row 3 end*/}
                    </Box>
                    {/* Card box container end */}
                  </Grid>
                  {/* Grid for card box end*/}
                </Grid>
              </Grid>
              {/* Fourth row end*/}
            </Grid>
          </Container>
          {/* Container body ends */}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const StarIcon = () => {
  return (
    <React.Fragment>
      <StarRoundedIcon
        style={{
          color: "#fff600",
          marginRight: "0.5rem",
        }}
      />
    </React.Fragment>
  );
};

const PromoText = () => {
  return (
    <React.Fragment>
      <Box height="10rem">
        <Box textAlign="center" p={2}>
          <Typography fontWeight="bold" variant="h5">
            - You'll get -
          </Typography>

          <Typography fontWeight="light" variant="body1">
            <Box display="flex" alignItems="center" justifyContentContent="center">
              <StarIcon />
              Professional Trainers
            </Box>
            <Box display="flex" alignItems="center" justifyContentContent="center">
              <StarIcon />
              Certificate
            </Box>
            <Box display="flex" alignItems="center" justifyContentContent="center">
              <StarIcon />
              Online and Offline course
            </Box>
            <Box display="flex" alignItems="center" justifyContentContent="center">
              <StarIcon />
              Seminar kit
            </Box>
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};
