import { productsConstants } from "../constants";
import { productService } from "../../_services/productService";

const productServiceInstance = new productService();

export const productsActions = {
  getDetail,
};

function getDetail(params) {
  return async (dispatch) => {
    dispatch({
      type: productsConstants.GET_DETAIL_REQUEST,
      payload: {
        isLoading: true,
        detail: {},
      },
    });

    const { data, isError, message } = await productServiceInstance.getDetail(
      params
    );

    if (isError) {
      return dispatch({
        type: productsConstants.GET_DETAIL_FAILED,
        payload: {
          isLoading: false,
          isError: true,
          status: message ? message.status : "",
          statusText: message ? message.statusText : "",
        },
      });
    } else {
      
      let remapDetail = data? {...data} : {};

      return dispatch({
        type: productsConstants.GET_DETAIL_SUCCESS,
        payload: {
          isLoading: false,
          isError: false,
          detail: data,
        },
      });
    }
  };
}
