import React from "react";
import { Container, CssBaseline } from "@material-ui/core";
import { RegistrationContainer } from "../../containers/Auth";

export function RegistrationPage() {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <RegistrationContainer />
    </Container>
  );
}
