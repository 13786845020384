import React from "react";
import {
  Card,
  CardContent,
  ButtonBase,
  makeStyles,
  Grid,
  Typography,
  Button,
  Box,
  Divider,
} from "@material-ui/core";
import AccessTimeRoundedIcon from "@material-ui/icons/AccessTimeRounded";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import FaceRoundedIcon from "@material-ui/icons/FaceRounded";
import { history } from "../../_helpers";
import { differenceInDays } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  image: {
    width: 200,
    height: 200,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  box: {
    height: "100%",
    width: "100%",
  },
  container: {
    height: "10rem",
  },
  innerContainer: {
    height: "100%",
  },
  item: {
    flex: 1,
  },
}));

const ProductCard = ({ className, product, ...rest }) => {
  const classes = useStyles();

  // const dateOnly = {
  //   day: "numeric",
  //   month: "long",
  //   weekday: "long",
  //   year: "numeric",
  // };

  // const timeOnly = {
  //   hour: "numeric",
  //   minute: "numeric",
  // };
  const currencyOption = {
    style: "currency",
    currency: "IDR",
    maximumSignificantDigits: 6,
  };

  // const eventStartDate = new Intl.DateTimeFormat("id-ID", dateOnly).format(
  //   new Date(product.event_start)
  // );
  // const eventStartTime = new Intl.DateTimeFormat("id-ID", timeOnly).format(
  //   new Date(product.event_start)
  // );

  const eventRegistStart = new Intl.DateTimeFormat("id-ID", {
    day: "numeric",
    month: "long",
  }).format(new Date(product.registration_start));

  const eventRegistEnd = new Intl.DateTimeFormat("id-ID", {
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(new Date(product.registration_end));

  const eventDays = differenceInDays(
    new Date(product.event_end),
    new Date(product.event_start)
  );

  const eventPrice = new Intl.NumberFormat("id-ID", currencyOption).format(
    product.event_price
  );

  function handleGetProduct(id) {
    history.push("/products/" + id);
  }

  return (
    <Card>
      <CardContent>
        <Grid container xs={12} spacing={2}>
          <Grid item container xs={12} md>
            <ButtonBase className={classes.image}>
              <img
                className={classes.img}
                alt="training"
                src="https://via.placeholder.com/150"
              />
            </ButtonBase>
          </Grid>
          {/* Column 1 */}

          <Grid item xs={12} md={7} container direction="column">
            <Grid item xs>
              <Typography variant="h4" component="h1">
                <Box fontWeight="bold">{product.name}</Box>
                <Divider></Divider>
              </Typography>
              <Typography color="textSecondary" variant="body2" gutterBottom>
                <Box fontStyle="italic" fontWeight="bold"></Box>
              </Typography>

              <Typography variant="body1">
                {/* for hide long text */}
                <Box maxHeight={100} textOverflow="ellipsis" overflow="hidden">
                  {product.event_description}
                </Box>
              </Typography>
            </Grid>
            {/* <Grid item>
                <Typography color="textSecondary" variant="body2">
                  Registration date :{" "}
                  <Box fontStyle="italic">
                    {eventRegistStart} - {eventRegistEnd}{" "}
                  </Box>
                </Typography>
              </Grid> */}
            <Grid item>
              <Box display="flex" alignItems="center" fontWeight="bold">
                <Typography variant="body1" color="primary">
                  <Box fontWeight="bold">
                    Pendaftaran : {eventRegistStart} - {eventRegistEnd}
                  </Box>
                </Typography>
              </Box>

              <Typography variant="h5">
                <Box fontWeight="bold">{eventPrice}</Box>
              </Typography>
            </Grid>
          </Grid>
          {/* Column 2 */}
          <Grid item xs={12} md container direction="column" spacing={1}>
            <Grid item xs className={classes.statsItem}>
              <AccessTimeRoundedIcon
                className={classes.statsIcon}
                color="action"
              />
              <Typography variant="body2" display="inline">
                {eventDays} Days
              </Typography>
            </Grid>

            <Grid item xs className={classes.statsItem}>
              <MenuBookRoundedIcon
                className={classes.statsIcon}
                color="action"
              />
              <Typography variant="body2" display="inline">
                {product.countLesson} 12 Lessons
              </Typography>
            </Grid>

            <Grid item xs className={classes.statsItem}>
              <FaceRoundedIcon className={classes.statsIcon} color="action" />
              <Typography variant="body2" display="inline">
                {product.level} All Level
              </Typography>
            </Grid>

            <Grid item xs>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleGetProduct(product.id)}
              >
                Details
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export { ProductCard };
