import { config } from "./config";
import { authHeader } from "../_helpers";

export const transactionService = {
    getByUser
}

function getByUser(userId) {
    let {Authorization} = authHeader()
    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type' : 'application/json',
        'Authorization' : Authorization,
        'Access-Control-Allow-Origin': '*',
        'X-Requested-With': 'XMLHttpRequest'
      },
    }
    return fetch(`${config.apiUrl+'/user'+config.checkout}`,requestOptions)
      .then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if (response.status === 401) {
          console.log("error")
        }
        const error =(data && data.message) || response.statusText;
        return Promise.reject(error)
      }
      return data;
    })
  }