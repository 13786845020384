import React, { useEffect } from "react";
import {
  Grid,
  List,
  Typography,
  Drawer,
  Toolbar,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router";

const styles = makeStyles((theme) => ({
  drawer: {
    zIndex: 1100,
  },
  paperContent: {
    marginLeft: "12rem",
    minWidth: "70vw",
    padding: theme.spacing(3),
  },
  paperMenu: {
    maxWidth: "12rem",
    // padding: theme.spacing(2)
  },
}));

export function LessonMenu({
  listActionDispatcher,
  lessonList,
  lessonId,
}) {
  const classes = styles();
  const history = useHistory();

  useEffect(() => {
    if (typeof listActionDispatcher == "function")
      listActionDispatcher(lessonId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonId]);

  return (
    <>
      <Drawer variant="permanent" className={classes.drawer}>
        <Toolbar />
        <List className={classes.paperMenu}>
          {lessonList &&
            lessonList.map((val, index) => (
              <ListItem
                key={index}
                button
                onClick={() =>
                  history.push(`/products/${lessonId}/lesson/${val.id}`)
                }
                children={<ListItemText children={val.title} />}
              />
            ))}
        </List>
      </Drawer>
    </>
  );
}
