import { combineReducers } from "redux";
import { auth, profile } from "./auth.reducer";
import { lessonV2, contentV2 } from "./lesson.reducer";
import { productsV2 } from './products.reducer'
import { appState } from "./appState.reducer";

//legacy redux
import { authentication } from "../../_reducers/authentication.reducer";
import { registration } from "../../_reducers/registration.reducer";
import { alert } from "../../_reducers/alert.reducer";
import {
  products,
  selectedProducts,
  lesson,
  content,
} from "../../_reducers/products.reducer";
import { checkout } from "../../_reducers/checkout.reducer";
import { transaction } from "../../_reducers/transaction.reducer";
import { user } from "../../_reducers/user.reducer";
//////

export const baseReducer = combineReducers({
  //legacy state
  authentication,
  registration,
  selectedProducts,
  products,
  alert,
  checkout,
  transaction,
  user,
  lesson,
  content,

  auth,
  appState,
  profile,
  lessonV2,
  contentV2,
  productsV2,
});
