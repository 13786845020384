import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { 
    Button, 
    Avatar,
    Grid,
    CssBaseline,
    Divider
} from '@material-ui/core';

const desktopMenuStyles = makeStyles((theme) => ({
    
    boxbutton: {
        padding: theme.spacing(2),
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
        },
      },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      }
}));


export const DesktopMenu = (DesktopButton) => {
    const classes = desktopMenuStyles();
    let  {ButtonMenu, ButtonUser} = DesktopButton.DesktopButton
    const loggingIn = useSelector(state => state.authentication.loggedIn)
    // console.log(props)
    return(
        <React.Fragment>
            <div className={classes.sectionDesktop} >
                <CssBaseline />
                <Grid 
                container 
                direction="row" 
                justifyContent="space-around" 
                className={classes.boxbutton}
                alignItems="center">
                    <Avatar edge="start" alt="Remy Sharp" src="/static/images/avatar/1.jpg" component={RouterLink} to="/home" />
                    <Grid item>
                        <Button
                            color="inherit"
                            component={RouterLink} to="/products"
                        >
                            {ButtonMenu.Button1}
                            </Button>
                    </Grid>
                    
                    {
                        !loggingIn &&
                        <Grid item>
                            <Button
                                color="inherit"
                                component={RouterLink} to="/about"
                            >
                                {ButtonMenu.Button2}</Button>
                        </Grid> 
                    }
                    {
                        !loggingIn &&
                        <Grid item>
                            <Button
                                color="inherit"
                                component={RouterLink} to="/contact"
                            >
                                {ButtonMenu.Button3}</Button>
                        </Grid>
                    }

                    <Divider orientation="vertical" />

                    {
                        loggingIn && 
                        <Grid item>
                            <Button 
                                color="inherit"
                                component={RouterLink} to="/mytraining">
                                {ButtonUser.ButtonMyTraining}
                            </Button>
                        </Grid>
                    }

                    {
                        loggingIn && 
                        <Grid item>
                            <Button 
                                color="inherit"
                                component={RouterLink} to="/profile">
                                {ButtonUser.ButtonProfile}
                            </Button>
                        </Grid>
                    }
                    {
                        loggingIn && 
                        <Grid item>
                            <Button 
                                color="inherit"
                                component={RouterLink} to="/login">
                                {ButtonUser.ButtonLogout}
                            </Button>
                        </Grid>
                    }    

                    {
                        !loggingIn &&
                        <Grid item>
                            <Button
                                color="inherit"
                                component={RouterLink} to="/login">
                                    {ButtonUser.ButtonLogin}
                            </Button>    
                        </Grid>
                    }
                    
                    {
                        !loggingIn &&
                        <Grid item>
                            <Button
                                color="inherit"
                                component={RouterLink} to="/register">
                                    {ButtonUser.ButtonRegister}
                            </Button>    
                        </Grid>
                    }
                </Grid>
            </div>
        </React.Fragment>
    )
}
