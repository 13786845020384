import React from "react";
import {
  Container,
  Typography,
  Box,
  makeStyles,
  Grid,
  Divider,
  Link,
} from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import PhoneIcon from "@material-ui/icons/Phone";

const contactPageStyle = makeStyles((theme) => ({
  image: {
    background: "url(https://source.unsplash.com/bGeupv246bM) no-repeat center",
    backgroundSize: "cover",
    width: "700px",
    height: "500px",
    marginTop: "1rem",
    clipPath: "polygon(0 0, 56% 1%, 100% 100%, 39% 100%)",
  },
  map: {
    width: "500px",
    height: "200px",
    border: "0",
    padding: "1rem",
    margin: "auto",
  },
  icon: {
    marginRight: "0.5rem",
  },
}));
export function ContactPage() {
  // const globalClasses = useStyles();
  const classes = contactPageStyle();
  return (
    <Container maxWidth>
      {/* Grid Start Container */}
      <Grid container direction="row" spacing={1}>
        {/* Grid for image contact */}
        <Grid
          item
          container
          xs={false}
          md={6}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Box className={classes.image}></Box>
        </Grid>
        {/* //Grid for Header Contact, Address and map */}
        <Grid item container xs={12} md={6}>
          {" "}
          <Box width={600} mt={3}>
            <Box display="block" textAlign="right">
              <Typography variant="h1">Get in touch</Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                let me help answer your question
              </Typography>
              <Divider />
            </Box>
            <Box display="block" mt={4}>
              <Typography variant="h5" component="h1">
                Office :
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Ruko Emerald Boulevard AA2-30, Jl. Jombang Raya, Bintaro Sektro
                9, Kota Tangerang Selatan, Banten 15227
              </Typography>

              <Typography variant="h5" component="h1">
                Contact
              </Typography>
              <ul>
                <li>Mse-kalibrasi.co.id</li>
                <li>Ldp-elektromedik.com</li>
                <li>msolusielektromedik@gmail.com</li>
                <li>Mtk-kalibrasi.blogspot.com</li>
                <li>mkontrindo@gmail.com</li>
              </ul>
            </Box>
            <Box display="flex" alignContent="center" alignItems="center">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.8753137992508!2d106.70112001413817!3d-6.280118463220499!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69fa95acbe1e73%3A0x7c3731b46f4fa723!2sRuko%20Emerald%20Boulevard%2C%20Jl.%20Jombang%20Raya%2C%20Parigi%2C%20Kec.%20Pd.%20Aren%2C%20Kota%20Tangerang%20Selatan%2C%20Banten%2015227!5e0!3m2!1sen!2sid!4v1614409219392!5m2!1sen!2sid"
                allowfullscreen=""
                loading="lazy"
                className={classes.map}
                id="google-map"
                title="google-map"
              ></iframe>
            </Box>
          </Box>
        </Grid>
        {/* Grid for medsoc */}
        <Grid item container xs={12} justifyContent="center">
          <Box display="flex" minWidth justifyContent="center" mt={8} mb={3}>
            <Link variant="body1" color="inherit" href="#" underline="none">
              <Box display="flex" flexDirection="row" width="200px">
                <FacebookIcon className={classes.icon} />
                <Typography>
                  <Box fontSize={18}>Facebook</Box>
                </Typography>
              </Box>
            </Link>
            <Link variant="body1" color="inherit" href="#" underline="none">
              <Box display="flex" flexDirection="row" width="200px">
                <InstagramIcon className={classes.icon} />
                <Typography>
                  <Box fontSize={18}>Instagram</Box>
                </Typography>
              </Box>
            </Link>

            <Box display="flex" flexDirection="row" width="200px">
              <PhoneIcon className={classes.icon} />
              <Typography>
                <Box fontSize={18}>+62 1234 5678</Box>
              </Typography>
            </Box>
          </Box>
        </Grid>
        {/* Grid ends container */}
      </Grid>
    </Container>
  );
}

export * from './ContactUsPage'

