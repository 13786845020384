import { config } from "./config";
import { authHeader } from "../_helpers"

export const productServices = {
  getAll,
  getById,
  getLesson,
  getContent
}

function getAll() {
  const requestOptions ={
    method: 'GET',
    headers: { 'Content-Type': 'application/json',
               'Access-Control-Allow-Origin': '*'
    }
  }
  return fetch(`${config.apiUrl+config.product}`, requestOptions)
    .then(handleResponse);
}

function  getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json',
               'Access-Control-Allow-Origin': '*'
    }
  };
  return fetch(`${config.apiUrl+config.product+'/'+id}`, requestOptions)
    .then(handleResponse)
}

function  getLesson(id) {
    let {Authorization} = authHeader()
    const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json',
               'Access-Control-Allow-Origin': '*',
               'X-Requested-With' : 'XMLHttpRequest',
               'Authorization' : Authorization,
    }
  };
  return fetch(`${config.apiUrl+config.product+'/'+id+config.lesson}`, requestOptions)
    .then(handleResponse)
}

function  getContent(id, contentId) {
  let {Authorization} = authHeader()
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json',
               'Access-Control-Allow-Origin': '*',
               'Authorization' : Authorization,
               'X-Requested-With' : 'XMLHttpRequest',
    }
  };
  return fetch(`${config.apiUrl+config.product+'/'+id+config.lesson+'/'+contentId}`, requestOptions)
    .then(handleResponse)
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        console.log("error")
      }
      const error =(data && data.message) || response.statusText;
      return Promise.reject(error)
    }
    return data;
  })
}
