import { Typography,Hidden,makeStyles } from "@material-ui/core";
import React from "react"
import logo  from "../../LOGO.png";

const jumbotronStyles = makeStyles((theme) => ({
    images: {
        maxWidth: "40rem",
        backgroundColor: "white",
    }
  }));
export const Jumbotron = () => {
    const classes = jumbotronStyles()
    return(
        <React.Fragment>
            <img src={logo} alt="LOGO-LPK" className={classes.images}></img>
            <Hidden smDown>
                <Typography  variant="h2">
                    Membangun Tenaga Elektromedis yang Berkualitas
                </Typography>
            </Hidden>
            <Hidden mdUp>
                <Typography  variant="h4">
                    Membangun Tenaga Elektromedis yang Berkualitas
                </Typography>
            </Hidden>
        </React.Fragment>
    )
}