import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useRouteMatch } from "react-router";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  CircularProgress,
  Paper,
  Hidden,
  Typography,
} from "@material-ui/core";

import {
  ProductDetailHeader,
  ProductDetailBody,
} from "../../containers/ProductDetail";
import { LoadingButton } from "../../components/Core";
import { productsActions } from "../../_redux/actions";
import { transactionAction } from "../../_actions";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  productCard: {
    height: "100%",
  },
  paperHeader: {
    backgroundColor: theme.palette.grey[200],
  },
}));

export function ProductDetailPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isLoading, detail } = useSelector((state) => state.productsV2);
  const {
    params: { id},
  } = useRouteMatch();

  useEffect(() => {
    dispatch(productsActions.getDetail(id));
    dispatch(transactionAction.getByUser())
  }, []);

  return (
    <Box ml={3} mr={3} className={classes.root}>
      {isLoading === true && <LoadingButton />}
      {isLoading === false && (
        <ProductDetailHeader
          productTitle={detail.name}
          registrationStart={detail.registration_start}
          registrationEnd={detail.registration_end}
          // productImg={`http://lpkadm.rfuady.tech./products/${detail.thumbnail}`}
        />
      )}
      {isLoading === false && 
        <ProductDetailBody 
          detail={detail}
        />}
    </Box>
  );
}
