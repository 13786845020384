import React from "react";
import { CssBaseline } from "@material-ui/core";
import { Layout } from "./Layout";

export const LandingPage = () => {

    return(
        <React.Fragment>
            <CssBaseline />

            <Layout />
            
        </React.Fragment>
    )
}