import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { productAction } from "../../_actions";
import { userActions } from "../../_actions";
import { transactionAction } from "../../_actions";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import {Page} from "../../_components/Page";
import { ProductDetail } from "../../_components/Product";
import { useParams } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const ProductDetailPage = () => {
  const classes = useStyles();
  const selectedProd = useSelector(state => state.selectedProducts.items);
  const isLoading = useSelector(state => state.products.loading);
  const dispatch = useDispatch();
  const {id} = useParams();
  useEffect(() => {
    dispatch(productAction.getById(id));
  },[id, dispatch]);
  useEffect(() => {
		dispatch(userActions.getMyData());
	}, [dispatch]);
  useEffect(() => {
		dispatch(transactionAction.getByUser());
	}, [dispatch]);

  return (
    <Page
      className={classes.root}
      title="Products"
    >
      <Container maxWidth={false}>
        <Box mt={3} >
          <Grid 
            container
            spacing ={3}
          > 
          {isLoading ? <span style={{
                position: 'absolute', left: '50%', top: '50%',
                transform: 'translate(-50%, -50%)'
            }}><CircularProgress align="center" /></span> : ""}
          {selectedProd && <ProductDetail selectedProd={selectedProd} />} 
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default ProductDetailPage;
