import React from 'react';
import {
    Typography
} from '@material-ui/core';
import { useStyles } from "../Styles";


//Text for HomePage
const HomeText = "Kami memberikan pelatihan berkualitas dan profesional untuk tenaga elektromedis \n- Silakan klik pada tab Product untuk memilih jenis dan tanggal pelatihan\n - Apabila sudah melakukan pembayaran, silakan klik pada tab My Training"
//


export default function PaperHome() {
    const classes = useStyles();
    return (
        <div className={classes.paper}>
            <Typography component="h1" variant="h5" className={classes.button}>
            Kami memberikan pelatihan berkualitas dan profesional untuk tenaga elektromedis 
            <br />
            <br />
            - Silakan klik pada tab Product untuk memilih jenis dan tanggal pelatihan 
            <br />
            - Apabila sudah melakukan pembayaran, silakan klik pada tab My Training
            </Typography>
            
        </div>
    )
}
