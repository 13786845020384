export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    GETMYDATA_REQUEST: 'USERS_GETMYDATA_REQUEST',
    GETMYDATA_SUCCESS: 'USERS_GETMYDATA_SUCCESS',
    GETMYDATA_FAILURE: 'USERS_GETMYDATA_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    EDITDATA_REQUEST: 'USERS_EDITDATA_REQUEST',
    EDITDATA_SUCCESS: 'USERS_EDITDATA_SUCCESS',
    EDITDATA_FAILURE: 'USERS_EDITDATA_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    FORGOT_PASSWORD_REQUEST: 'USERS_FORGOT_REQUEST',
    FORGOT_PASSWORD_SUCCESS: 'USERS_FORGOT_SUCCESS',
    FORGOT_PASSWORD_FAILED: 'USERS_FORGOT_FAILED',

    RESET_PASSWORD_REQUEST: 'USERS_RESET_REQUEST',
    RESET_PASSWORD_SUCCESS: 'USERS_RESET_SUCCESS',
    RESET_PASSWORD_FAILED: 'USERS_RESET_FAILED',
};
