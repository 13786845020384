import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productAction } from "../../_actions";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  CircularProgress,
  Grow,
} from "@material-ui/core";
import { Page } from "../../_components/Page";
// import Toolbar from './Toolbar';

import { ProductPaper } from "../../_components/Product";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  productCard: {
    height: "100%",
  },
}));

const ProductList = () => {
  const classes = useStyles();
  const products = useSelector((state) => state.products.items);
  const isLoading = useSelector((state) => state.products.loading);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(productAction.getAll());
  }, [dispatch]);

  // console.log(typeof(products))
  return (
    <Page className={classes.root} title="Products">
      <Container maxWidth={false}>
        {/* <Toolbar /> */}
        <Box mt={3}>
          <Grid container spacing={3}>
            {isLoading ? (
              <span
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <CircularProgress align="center" />
              </span>
            ) : (
              ""
            )}
            {products &&
              products.map((product) => (
                <Grow in timeout={800} key={product.id}>
                  <Grid item key={product.id} lg={6} xs={12}>
                    {/* <ProductCard
                    className={classes.product}
                    product={product}
                  /> */}

                    <ProductPaper
                      className={classes.product}
                      product={product}
                    />
                  </Grid>
                </Grow>
              ))}
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default ProductList;
