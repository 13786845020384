import {lessonConstants} from '../constants'
import {lessonService} from '../../_services/lessonService'
import { authActions } from "./auth.actions";

const lessonServiceInstance = new lessonService();

export const lessonActions ={
  lesson,
  lessonContent,
}

function lesson(params) {
  return async (dispatch) => {
    dispatch({
      type: lessonConstants.GET_LESSON_REQUEST,
      payload: { isLoading: true },
    })

    const {data, isError, message} =  await lessonServiceInstance.getLesson(params)

    if (isError) {
      return dispatch({
        type: lessonConstants.GET_LESSON_FAILED,
        payload: {
          isLoading: false,
          isError: true,
          status: message ? message.status : "",
          statusText: message ? message.statusText : "",
        }
      })
    } else {
      return dispatch({
        type: lessonConstants.GET_LESSON_SUCCESS,
        payload: {
          isLoading:false,
          isError: false,
          data: data.contents,
          title: data.name
        }
      })

    }
  }
}

function lessonContent(params) {
  return async (dispatch) => {
    dispatch({
      type: lessonConstants.GET_LESSON_CONTENT_REQUEST,
      payload: { isLoading: true },
    })

    const {data, isError, message} =  await lessonServiceInstance.getContent(params)

    if (isError) {
      return dispatch({
        type: lessonConstants.GET_LESSON_CONTENT_FAILED,
        payload: {
          isLoading: false,
          isError: true,
          status: message ? message.status : "",
          statusText: message ? message.statusText : "",
        }
      })
    } else {
      return dispatch({
        type: lessonConstants.GET_LESSON_CONTENT_SUCCESS,
        payload: {
          isLoading:false,
          isError: false,
          data: data
        }
      })

    }
  }
}