import { makeStyles } from "@material-ui/core/styles";

//customize Styles CSS
export const useStyles = makeStyles((theme) => ({
  root: {
    height: "93vh",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/oCLuFi9GYNA)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paperAlign: {
    margin: theme.spacing(12, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    margin: theme.spacing(3, 0, 2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logoDisplay: {
    // margin: theme.spacing(2, 1),
    display: "block",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "auto%",
  },
}));
