import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  makeStyles,
  Toolbar,
  Paper,
  Drawer,
  List,
} from "@material-ui/core";
import { Route, Switch, useHistory, useRouteMatch } from "react-router";

import { LessonMenu, LessonContent } from "../../containers/Lesson";

import { authActions, lessonActions } from "../../_redux/actions";
import { Typography } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import PrivateRoute from "../../components/PrivateRoute";

const styles = makeStyles((theme) => ({
  paperContent: {
    marginLeft: "12rem",
    minWidth: "70vw",
    padding: theme.spacing(3),
  },
}));

export function LessonPage() {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();

  const {params: {
    id
  }} = useRouteMatch();

  const lesson = useSelector((state) => state.lessonV2);

  return (
    <>
      <LessonMenu
        listActionDispatcher={(ctx) => dispatch(lessonActions.lesson(ctx))}
        lessonList={lesson.data}
        lessonId={id}
      />
      <Paper className={classes.paperContent}>
        <Typography variant="h4">
          {lesson.title}
        </Typography>
        <Divider />
        <Switch>
          <PrivateRoute 
            exact 
            path={`/products/:id/lesson/:contentId`} 
            component={
              ()=> <LessonContent />} />
        </Switch>
      </Paper>
    </>
  );
}
