import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, 
    Button, Menu, 
    Grid,
    CssBaseline,
    Avatar
} from '@material-ui/core';

import { DesktopMenu } from "./DesktopMenu";
import { MobileMenuItem } from "./MobileMenuItem";

const appsBarStyles = makeStyles((theme) => (
    {
    grow: {
      flexGrow: 1,
    },

    boxbutton: {
        padding: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'block',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
        },
      },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
    },
    logoSmall: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    appBar: {
        zIndex: 1500,
        
    },

}));

const DesktopButton = {
    ButtonMenu:{
        Button1: "Products",
        // Button2: "About",
        Button3: "Contact"
    },
    ButtonUser:{
        ButtonLogin: "Login",
        ButtonRegister: "Register",
        ButtonProfile: "Profile",
        ButtonLogout: "Logout",
        ButtonMyTraining: "My Training"
    }
}





export function AppsBar() {
    const classes = appsBarStyles();

    return (
        <React.Fragment>
            <AppBar position="relative" className={classes.appBar}>
                <DesktopMenu DesktopButton={DesktopButton}  />
                <MobileMenu DesktopButton={DesktopButton} />
            </AppBar>
            
        </React.Fragment>
    )
}








const MobileMenu = () => {
    const classes = appsBarStyles();
    const mobileMenuId = 'primary-menu-mobile';
    const [mobileMoreAnchorEl,setMobileMoreAnchorEl] = useState(null);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(0);
    }

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
      };
      
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
                <MobileMenuItem />
        </Menu>
    );
    return(
        <React.Fragment>
            <div className={classes.sectionMobile} >
                <CssBaseline />
                <Grid 
                container
                direction="row" 
                justifyContent="space-around" 
                alignItems="center"
                >
                    <Grid item>
                        <Avatar edge="start" alt="Remy Sharp" src="/static/images/avatar/1.jpg" className={classes.logoSmall} />
                    </Grid>
                    <Grid item>
                        <Button
                            color="inherit"
                            component={RouterLink} to="/products"
                        >
                            Store
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            Account
                        </Button>
                    </Grid>
                </Grid>

                {/* PopupMenu */}
                {renderMobileMenu}
            </div>
        </React.Fragment>
    )
}

