import React from "react";
import { CircularProgress } from "@material-ui/core";

export function LoadingButton() {
  return (
    <span
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <CircularProgress align="center" />
    </span>
  );
}
