import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router';
import { productAction, transactionAction, userActions } from "../../_actions";
import  {
    Grid
} from "@material-ui/core"
import { UserPaper } from "../../_components/Checkout/UserPaper";
import { PricePaper } from "../../_components/Checkout/PricePaper";


const CheckoutPageStyles =  makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.primary.dark,
        padding: theme.spacing(10,15,0),  
        height: "75vmin",
        width: "100vw",
        textAlign: "center"
    },
    fontColor: {
        color:  theme.palette.getContrastText(theme.palette.primary.dark),
        padding: theme.spacing(0,0,6)
    },
    fontBold:{
        fontWeight: 600
    }
    

}));

export const CheckoutPage = () => {
    const classes = CheckoutPageStyles();
    const selectedProducts = useSelector(state => state.selectedProducts.items)
    const transaction = useSelector(state => state.transaction.items)
    
    let paymentstatus = ""
    if (transaction && selectedProducts) {
        transaction
            .filter(val => val.product_id === selectedProducts.id )
            .map((val) => {
                if (val.transaction_status === 'PAID') {
                    paymentstatus="PAID"
                }
                if (val.transaction_status === 'UNPAID') {
                    paymentstatus="UNPAID"
                }
                if (val.transaction_status === 'EXPIRED') {
                    paymentstatus="EXPIRED"
                }
            })
    }
    const dispatch = useDispatch();
    const {id} = useParams();
    useEffect(
        () => {
            dispatch(productAction.getById(id));
            dispatch(userActions.getMyData());
            dispatch(transactionAction.getByUser());
        },[id]
    );
    const getUser = useSelector((state) => state.user);
    const userData = getUser && getUser.items

    return(
        <React.Fragment>
            <div className={classes.container}>
                <Typography variant="h4" className={classes.fontColor} gutterBottom>
                    Registration Summary
                </Typography>
                <Grid 
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="flex-start"
                >
                    <Grid item xs={12} sm={6}>
                        {userData &&  <UserPaper user={{userData}} /> }
                        
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {selectedProducts  && <PricePaper checkout={{selectedProducts}} paymentstatus={paymentstatus}/>}
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}

