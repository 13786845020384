import React from "react";
import {
  Grid,
  List,
  Typography,
  Drawer,
  Toolbar,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { useHistory, useRouteMatch,  Route, Switch,  } from "react-router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lessonActions } from "../../_redux/actions";
import { Box } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import parse from "html-react-parser";

export function LessonContent({contentActionDispatcher}) {
  const dispatch = useDispatch();
  const {data} = useSelector(state => state.contentV2)
  console.log(data)
  const {params: {
    contentId, id
  }} = useRouteMatch();

  useEffect(() => {
    // if (typeof listActionDispatcher == "function")
    // contentActionDispatcher({contentId, id});
    dispatch(lessonActions.lessonContent({contentId,id}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentId,id]);
  return (
    <>
      <Box mt={3} mb={3}>
        {data && <Typography variant="h5" children={data.title} />}
      </Box>
      <Divider />
      <Box>
        {data && <Typography variant="body" children={parse(data.description)} />}
      </Box>
    </>
  );
}
