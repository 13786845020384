import React from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Box,
  Typography,
  Divider,
  makeStyles,
  Paper,
} from "@material-ui/core";
import CalendarTodayRoundedIcon from "@material-ui/icons/CalendarTodayRounded";
import AccessTimeRoundedIcon from "@material-ui/icons/AccessTimeRounded";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import RoomRoundedIcon from "@material-ui/icons/RoomRounded";
import { differenceInDays } from "date-fns";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  trainerBox: {
    padding: theme.spacing(2, 4),
    margin: theme.spacing(2, 0),
  },
  actionBox: {
    padding: theme.spacing(3, 4),
  },
}));

export function ProductDetailBody({ detail }) {
  const classes = useStyles();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const transaction = useSelector((state) => state.transaction);
  let paidTransaction;
  if (transaction.items) {
    let trxArray = transaction.items;
    trxArray.forEach((trx) => {
      if (trx.transaction_status === "PAID" && trx.product_id === detail.id) {
        // console.log(trx)
        paidTransaction = trx;
      }
    });
  }

  console.log(paidTransaction);

  const history = useHistory();

  const handleButton = () => {
    history.push(`/checkout/${detail.id}`);
  };

  const dateOnly = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };

  const currencyOption = {
    style: "currency",
    currency: "IDR",
    maximumSignificantDigits: 6,
  };

  const eventStart = new Intl.DateTimeFormat("id-ID", {
    day: "numeric",
    month: "long",
  }).format(new Date(detail.event_start));

  const eventEnd = new Intl.DateTimeFormat("id-ID", dateOnly).format(
    new Date(detail.event_end)
  );

  const eventDays = differenceInDays(
    new Date(detail.event_end),
    new Date(detail.event_start)
  );
  const eventPrice = new Intl.NumberFormat("id-ID", currencyOption).format(
    detail.event_price
  );

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography variant="h5">Description</Typography>
          <Divider style={{ margin: "1rem" }} />
          {/* <Typography display="block">{detail.event_description}</Typography> */}
          <Typography style={{ whiteSpace: "pre-wrap" }} gutterBottom>
            {detail.event_description}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container direction="column">
            <Typography variant="h5">Trainer</Typography>
            <Divider style={{ margin: "1rem" }} />
            <Box className={classes.trainerBox}>
              {typeof detail.trainer_id !== "string" &&
                detail.trainer_id.map((val, index) => (
                  <Box key={index} maxHeight="30vh">
                    <Typography children={`- ${val.name}`} />
                  </Box>
                ))}
            </Box>
            <Box>
              <Paper className={classes.actionBox} elevation={4}>
                <Grid item container direction="row" alignItems="center">
                  <Grid item md={2} xs={2} container justifyContent="center">
                    <Box color="cyan">
                      <CalendarTodayRoundedIcon
                        color="primary"
                        fontSize="large"
                      />
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={5}>
                    <Box fontWeight="bold">
                      <Typography variant="h6">Tanggal Pelatihan : </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={5}>
                    <Typography variant="subtitle1">
                      {eventStart} - {eventEnd}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item container direction="row" alignItems="center">
                  <Grid item md={2} xs={2} container justifyContent="center">
                    <Box color="cyan">
                      <AccessTimeRoundedIcon color="primary" fontSize="large" />
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={5}>
                    <Box fontWeight="bold">
                      <Typography variant="h6">Durasi Pelatihan : </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={5}>
                    <Typography variant="subtitle1">
                      {eventDays} Hari
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item container direction="row" alignItems="center">
                  <Grid item xs={2} container justifyContent="center">
                    <Box color="cyan">
                      <RoomRoundedIcon color="primary" fontSize="large" />
                    </Box>
                  </Grid>
                  <Grid item md={4} xs={5}>
                    <Box fontWeight="bold">
                      <Typography variant="h6">Lokasi :</Typography>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={5}>
                    <Typography variant="subtitle1">
                      {detail.event_address}
                    </Typography>
                  </Grid>

                  <Box
                    item
                    xs={12}
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    <Typography variant="h4" children={eventPrice} />
                  </Box>
                  <Box
                    item
                    xs={12}
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    {!paidTransaction && (
                      <Button
                        variant="contained"
                        color="primary"
                        children="Join Now"
                        onClick={handleButton}
                        fullWidth
                      />
                    )}
                    {paidTransaction && (
                      <Button
                        variant="contained"
                        color="primary"
                        children="Go To Lesson"
                        onClick={() =>
                          history.push(`/products/${detail.id}/lesson`)
                        }
                        fullWidth
                      />
                    )}
                  </Box>
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
