import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import thunk from "redux-thunk";
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from '../_reducers';
import {baseReducer} from '../_redux/reducers'

// const loggerMiddleware = createLogger();
// const composeMiddleware = applyMiddleware(thunkMiddleware);

const combinedReducers = combineReducers({
    ...rootReducer,
    ...baseReducer
})

export const store = createStore(
    baseReducer,
    composeWithDevTools(
        applyMiddleware(
            thunk,
        )
    )
);