import React from 'react'
import {
  makeStyles,
  Typography,
  TextField,
  Grid,
  Container,
  CssBaseline, 
  Button
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { authActions } from '../../_redux/actions';
import { Link as RouterLink } from 'react-router-dom';

const style = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export  function ActivationPage() {
  const classes = style();
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)

  const {params} = useRouteMatch()

  useEffect(() => {
    dispatch(authActions.activate(params.key))
  }, [])


  return (
    <>
      <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        {
          auth.status == 404 && <Typography component="h1" variant="h5">
          Activation Token Invalid
        </Typography> 
        }
        {
          auth.status != 404 && 
          <Typography component="h1" variant="h5">
            User Activated
          </Typography>
        }
        {
          auth.status != 404 && 
          <Button component={RouterLink} to="/login" children={"Login"} />
        }
        
      </div>
    </Container>
    </>
  )
}
