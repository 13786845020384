import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import {
  makeStyles,
  Typography,
  TextField,
  Grid,
  Container,
  InputAdornment,
  IconButton,
  CssBaseline,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from 'yup';

import { userActions } from "../../_actions";

const style = makeStyles((theme) => ({
  root: {
    height: "93vh",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export function ResetPassword() {
  const classes = style();

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  
  const {token} = useParams();

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confpassword: "",
      token: token,
    },
    validationSchema: Yup.object({
      password: Yup.string().required("The Field is Required"),
      confpassword: Yup.string().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both password need to be the same"
        )
      })
    }),
    onSubmit: (ctx) => {
      // console.log(ctx);
      dispatch(userActions.resetPass(ctx))
    },
  });

  useEffect(() => {
    console.log(user? user.message : "")
    return () => {
    }
  }, [user])


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
          {
						user.message &&
						<Grid item xs={12}>
							<Alert severity={"success"}>{user.message}</Alert>
						</Grid>
					}
          {
						user.error &&
						<Grid item xs={12}>
							<Alert severity={"error"}>{user.error}</Alert>
						</Grid>
					}
          <span class="error" style={{ color: "red" }}>
          {formik.errors.confpassword}
          </span>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Email Address"
                {...formik.getFieldProps("email")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="New Password"
                type={values.showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...formik.getFieldProps("password")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="New Password"
                type={values.showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...formik.getFieldProps("confpassword")}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >Submit</Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
