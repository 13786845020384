import { config } from "./config"
import { authHeader } from "../_helpers";

export const checkoutServices = {
    invoiceReq
}

function invoiceReq(invParams) {
  // let user = JSON.parse(localStorage.getItem('user'));
  const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(invParams)
  }
  return fetch(`${config.apiUrl+config.checkout}`, requestOptions)
      .then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      console.log(response.ok)
      if (!response.ok) {
        if (response.status === 401) {
          console.log("error")
        }
        const error =(data && data.message) || response.statusText;
        return Promise.reject(error)
      }
      return data;
    })
  }