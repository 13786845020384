import { checkoutConstant } from "../_constants";
import { checkoutServices } from "../_services";
import { alertActions } from "./";


export const checkoutAction = {
    invoiceReq
}

function invoiceReq(invParams) {
    return  dispatch => {
        dispatch(request({invParams}));

        checkoutServices.invoiceReq(invParams)
            .then(
                checkout => {
                    dispatch(success(checkout))
                    window.open(checkout.results.invoice_url,'_blank')
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString))
                }
            )
    }

    function request(checkout) {
        return { type: checkoutConstant.POSTINVOICE_REQUEST, checkout}
    }
    function success(checkout) {
        return { type: checkoutConstant.POSTINVOICE_SUCCESS, checkout}
    }
    function failure(error) {
        return { type: checkoutConstant.POSTINVOICE_FAILURE, error}
    }
}
