import React from 'react';
import {
  Typography, Grid, makeStyles, Box, Paper,
  Hidden, CssBaseline, Divider, Chip, Button
} from '@material-ui/core';
import { history } from "../../_helpers";
import { pretest } from  "../../_helpers/pretest"
import { Link as RouterLink } from 'react-router-dom';

const MyTrainingStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
    textAlign: 'left',
    // color: theme.palette.text.secondary,
  },
  header: {
    fontWeight: 600
  },
  productTitle: {
    fontWeight: 600
  },
  statusPaid: {
    color: "white",
    backgroundColor: theme.palette.success.main
  },
  statusUnpaid: {
    // color: theme.palette.primary.main,
    backgroundColor: theme.palette.warning.light
  },
  statusExpired: {
    color: "white",
    backgroundColor: theme.palette.error.dark
  },
  buttonInvoice: {
    width: "8rem",
  },
  myTraining: {
    padding: theme.spacing(2),
    margin: theme.spacing(6,0,6),
  }, 
  
}))

export const MyTrainingList = ({ transaction }) => {
  let classes = MyTrainingStyles()
  const { items } = transaction

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <CssBaseline />
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          // alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12}>
            <Hidden smDown>
              <Typography variant="h3">
                <Box fontWeight="medium" mdUp>Training List</Box>
              </Typography>
            </Hidden>
            <Hidden mdUp>
              <Typography variant="h4">
                <Box fontWeight="medium" mdUp>Training List</Box>
              </Typography>
            </Hidden>
          </Grid>

            {/* <MyTraining /> */}
          <Grid item xs={12}>
            {
              items && items.map(
                (item) => {
                  return (
                    <React.Fragment>
                      <MyTraining transaction={item} />
                    </React.Fragment>
                  )
                }
              )
            }
          </Grid>
        </Grid>

      </Paper>
    </React.Fragment>
  )
}

const MyTraining = ({ transaction }) => {
  const classes=MyTrainingStyles()
  const {
    transaction_status,
    invoice_id,
    product_id,
    product_name,
    sku,
    // expiry_date,
    pretest,
    payment_channel,
    payment_method,
    payment_time,
    created_at
  } = transaction

  const trxDate = new Date(created_at)
  
  function checkTrxStatus() {
    switch (transaction_status) {
      case "UNPAID":
        return classes.statusUnpaid
    
      case "PAID" :
        return classes.statusPaid

      case "EXPIRED" :
        return classes.statusExpired
      default:
        break;
    }
  }

  function handleGetLesson(id) {
    history.push("/products/" + id+"/lesson");
  }

  function handleGetInvoice(id) {
    history.push("/checkout/" + id);
  }


  return (
    <React.Fragment>
      <Paper className={classes.myTraining}>

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Typography variant="body" className={classes.productTitle} gutterBottom>
          {product_name}
          <Typography variant="caption">
            ({sku})
          </Typography>
        </Typography>
      </Grid>
      <Divider />
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      // spacing={2}
      // className={classes.header}
      > 
        <Grid item xs={6} md={6} >
          <Typography variant="body2" >
            Transaction ID : {invoice_id}
          </Typography>
          <Typography variant="body2">
            Payment Method : {payment_method} ({payment_channel})
          </Typography>
          <Typography variant="caption">
            {payment_time && "Time : " + payment_time}
          </Typography>
          <Box>
            { pretest &&
              <Button
                variant="contained"
                size="medium"
                children="PRE TEST"
                to={{ pathname: pretest }} target="_blank"
                component={RouterLink}
              />
            }
          </Box>
        </Grid>
        <Grid item xs={3} md={3}>
          <Chip 
            size="small" 
            label={transaction_status} 
            className={checkTrxStatus()}
          />
          
        </Grid>
        <Grid item xs={3} md={3}>
          {
            transaction_status === 'PAID' &&
            <Button 
              variant="contained" 
              size="medium" 
              className={classes.buttonInvoice}
              onClick={() => handleGetLesson(product_id)}
            >
              TRAINING
            </Button> 
          }
          {
            transaction_status === 'UNPAID' &&  
            <Button 
              variant="contained" 
              size="medium" 
              className={classes.buttonInvoice}
              onClick={() => handleGetInvoice(product_id)}
              >
              INVOICE
            </Button>
          }
        </Grid>
      </Grid>
      
      </Paper>

    </React.Fragment>
  )
}