import { baseService } from "./baseService";


export class authService extends baseService {
  login(payload) {
    let body = {
      email: payload.email,
      password: payload.password,
      remember_me: payload.remember
    }
    return this.basePOST("/login", body, false)
  }

  register(payload) {
    let body = {
      email: payload.email,
      password: payload.password,
      password_confirmation: payload.password_confirmation,
      name: payload.name,
      date_of_birth: payload.date_of_birth,
      phone: payload.phone,
      address: payload.address,
    }
    return this.basePOST("/signup", body, false)
  }

  profile() {
    return  this.baseGET("/user", true)
  }

  editProfile(payload) {
    let body = {
      ...payload
    }
    return this.basePOST("/profile/edit", body, true)
  }

  activate(key) {
    return this.baseGET(`/activate/${key}`, false)
  }
}