import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { MenuItem } from "@material-ui/core";

export function MobileMenuItem(isAuthenticated) {

  return (
    <React.Fragment>
      {isAuthenticated && (
        <MenuItem component={RouterLink} to="/profile">
          {"Profile"}
        </MenuItem>
      )}

      {isAuthenticated && (
        <MenuItem component={RouterLink} to="/mytraining">
          {"My Training"}
        </MenuItem>
      )}

      <MenuItem component={RouterLink} to="/login">
        {isAuthenticated ? "Logout" : "Login"}
      </MenuItem>
      {!isAuthenticated && (
        <MenuItem component={RouterLink} to="/register">
          {"Register"}
        </MenuItem>
      )}
    </React.Fragment>
  );
};
