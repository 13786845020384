import { productsConstants } from '../constants/products.constant'

const initialState = {};

export function productsV2(state = initialState, action) {
  switch (action.type) {
    case productsConstants.GET_DETAIL_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    case productsConstants.GET_DETAIL_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case productsConstants.GET_DETAIL_FAILED:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}