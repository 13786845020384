import React,{useState} from "react";
import {
  makeStyles,
  Typography,
  TextField,
  Grid,
  Container,
  CssBaseline, 
} from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import { useFormik } from "formik";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../_actions";

const style = makeStyles((theme) => ({
  root: {
    height: "93vh",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  errorText: {
    color: "red",
    textAlign: "center",
  },
}));

export function ForgotPassword() {
  const classes = style();

  const dispatch = useDispatch()
  const user = useSelector(state => state.user)


  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (ctx) => {
      dispatch(userActions.forgot(ctx))
    },
  });
  
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        {
						user.message &&
						<Grid item xs={12}>

							<Alert severity={"success"}>{user.message}</Alert>
						</Grid>
					}
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Email Address"
                {...formik.getFieldProps("email")}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >Submit</Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
