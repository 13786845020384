import React from 'react';
import { 
    Grid, 
    CssBaseline
    } from '@material-ui/core';
import PaperHome from '../../_components/PaperHome';
import { useStyles } from "../../_components/Styles";



export default function HomePage() {
    const classes = useStyles();
    return( 
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image}>
                {/* 
                    left Side of the page, bisa jadi di assign component image / logo 
                */}   
            </Grid> 
            <Grid item xs={12} sm={8} md={5} elevation={6} square="true">
                <PaperHome />
            </Grid>
        </Grid>
    )
}