import { authConstants } from "../constants";

const initialState = {};

export function auth(state = initialState, action) {
  switch (action.type) {
    case authConstants.REGISTER_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    case authConstants.REGISTER_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case authConstants.REGISTER_FAILED:
      return {
        ...state,
        ...action.payload,
      };
    case authConstants.LOGIN_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case authConstants.LOGIN_FAILED:
      return {
        ...state,
        ...action.payload,
      };
    case authConstants.LOGIN_STORE_TOKEN:
      return {
        ...state,
        ...action.payload,
      };
    case authConstants.LOGIN_REMOVE_TOKEN:
      return {
        ...state,
        ...action.payload,
      };
    case authConstants.RESET_AUTH_TOKEN:
      return {
        ...state,
        ...action.payload,
      };
    case authConstants.CHECK_LOCAL_TOKEN:
      return {
        ...state,
        ...action.payload,
      };
    case authConstants.FORGOT_PASS_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    case authConstants.FORGOT_PASS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case authConstants.FORGOT_PASS_FAILED:
      return {
        ...state,
        ...action.payload,
      };
    case authConstants.RESET_PASS_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    case authConstants.RESET_PASS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case authConstants.RESET_PASS_FAILED:
      return {
        ...state,
        ...action.payload,
      };
    case authConstants.ACTIVATE_ACCOUNT_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    case authConstants.ACTIVATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case authConstants.ACTIVATE_ACCOUNT_FAILED:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export function profile(state = initialState, action) {
  switch (action.type) {
    case authConstants.PROFILE_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    case authConstants.PROFILE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case authConstants.PROFILE_FAILED:
      return {
        ...state,
        ...action.payload,
      };

      case authConstants.PROFILE_EDIT_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    case authConstants.PROFILE_EDIT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case authConstants.PROFILE_EDIT_FAILED:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}