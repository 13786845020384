import { transactionConstant } from "../_constants";

export function transaction(state = {}, action) {
    switch (action.type) {
        case transactionConstant.GETALLINVOICE_REQUEST:
            return {
                loading: true,
            };
        case transactionConstant.GETALLINVOICE_SUCCESS:
            return {
                loading: false,
                items: action.transaction.data,
            }
        case transactionConstant.GETALLINVOICE_FAILURE:
            return {
                loading:false,
                error: action.error
            }
        default:
            return state
    }
}