import React, { useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";

import { history } from "./_helpers";
import { alertActions } from "./_actions";
import { PrivateRoute } from "./_components";

// import SignUp from './_components/SignUp';
import Routes from "./Routes";
import HomePage from "./pages/Home";
import LoginPage from "./pages/Auth";
import ResetPassword from "./pages/Auth";
import SignUpPage from "./pages/Registration";
import AboutPage from "./pages/About";
import { ContactPage } from "./pages/Contact";
import ProductList from "./pages/ProductsList";
import { Profile } from "./pages/Profile";
import ProductDetailPage from "./pages/ProductDetail";
import { LandingPage } from "./pages/Landing";
import { ThemeProvider, unstable_createMuiStrictModeTheme as createTheme } from "@material-ui/core/styles";
import { AppsBar } from "./_components/AppBar";
import { CheckoutPage } from "./pages/Checkout";
import { MyTrainingPage, MyTrainingDetailPage } from "./pages/MyTraining";
import ForgotPassword from "./pages/Auth/ForgotPassword";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1278AC",
    },
    secondary: {
      main: "#8DC641",
    },
  },
});

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    
  }, []);

  return (
    <ThemeProvider theme={theme}>
        <Routes />
        {/* <AppsBar position="fixed" /> */}
        {/* <Switch>
          <PrivateRoute exact path="/home" component={HomePage} />
          <Route path="/login" component={LoginPage} />
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/signup">
            <SignUpPage />
          </Route>
          <Route exact path="/about">
            <AboutPage />
          </Route>
          <Route exact path="/contact">
            <ContactPage />
          </Route>
          <Route exact path="/forgotpassword">
            <ForgotPassword />
          </Route>
          <Route exact path="/resetpassword/:token">
            <ResetPassword />
          </Route>
          <Route exact path="/products">
            <ProductList />
          </Route>
          <Route exact path="/products/:id">
            <ProductDetailPage />
          </Route>
          <PrivateRoute exact path="/profile">
            <Profile />
          </PrivateRoute>
          <PrivateRoute exact path="/mytraining">
            <MyTrainingPage />
          </PrivateRoute>
          <PrivateRoute path="/products/:id/lesson">
            <MyTrainingDetailPage />
          </PrivateRoute>
          <PrivateRoute exact path="/checkout/:id">
            <CheckoutPage />
          </PrivateRoute>
        </Switch> */}
    </ThemeProvider>
  );
};

export default App;
