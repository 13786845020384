import { checkoutConstant } from "../_constants";

export function checkout(state = {}, action) {
    switch (action.type) {
        case checkoutConstant.POSTINVOICE_REQUEST:
            return {
                loading: true,
            };
        case checkoutConstant.POSTINVOICE_SUCCESS:
            return {
                loading: false,
                items: action.data,
                invoice_url: action.checkout.invoice_url
            }
        case checkoutConstant.POSTINVOICE_FAILURE:
            return {
                loading:false,
                error: action.error
            }
        default:
            return state
    }
}