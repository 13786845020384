import React from "react";
import {
  makeStyles,
  FormControl,
  InputLabel,
  Box,
  OutlinedInput,
} from "@material-ui/core";

const style = makeStyles(() => ({
  root: {
    color: "#09101D",
    "& .MuiInputBase-root": {
    },
    "& .MuiFormLabel-root": {
      color: "#6D7580",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(10px, -5px) scale(0.75)",
    },
    "& .Mui-disabled": {
      background: "#F4F6F9",
      "& p": {
        color: "#000",
      },
    },
  },
}));

export function InputTextField(props) {
  const classes = style();

  return (
    <>
      <FormControl variant="outlined" className={classes.root}>
        <>
          <InputLabel>
            {props.label}
            {props.required ? (
              <Box component="span" color="text.error">
                {" * "}
              </Box>
            ) : (
              ""
            )}
          </InputLabel>

          <OutlinedInput
            {...props}
            value={props.value || ""}
            required={false}
          />
        </>
      </FormControl>
    </>
  );
}
