import { alertActions } from "./";
import { transactionService } from "../_services";
import { transactionConstant } from "../_constants";

export const transactionAction = {
    getByUser
}

function getByUser() {
    return dispatch => {
        dispatch(request())

        transactionService.getByUser()
            .then(
                transaction => {
                    dispatch(success(transaction.results))
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error));
                } 
            )
    }

    function request() { return { type: transactionConstant.GETALLINVOICE_REQUEST}}
    function success(transaction) { return { type: transactionConstant.GETALLINVOICE_SUCCESS, transaction}}
    function failure(error) { return { type: transactionConstant.GETALLINVOICE_FAILURE, error}}
}