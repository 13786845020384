export const productsConstant = {
    GETALL_REQUEST: 'PRODUCTS_GETALL_REQUEST',
    GETALL_SUCCESS: 'PRODUCTS_GETALL_SUCCESS',
    GETALL_FAILURE: 'PRODUCTS_GETALL_FAILURE',
    
    GETBYID_REQUEST: 'PRODUCTS_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'PRODUCTS_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'PRODUCTS_GETBYID_FAILURE',

    CHECKOUT_REQUEST: 'PRODUCTS_CHECKOUT_REQUEST',
    CHECKOUT_SUCCESS: 'PRODUCTS_CHECKOUT_SUCCESS',
    CHECKOUT_FAILURE: 'PRODUCTS_CHECKOUT_FAILURE',

    GETLESSON_REQUEST: 'PRODUCTS_GETLESSON_REQUEST',
    GETLESSON_SUCCESS: 'PRODUCTS_GETLESSON_SUCCESS',
    GETLESSON_FAILURE: 'PRODUCTS_GETLESSON_FAILURE',

    GETCONTENT_REQUEST: 'PRODUCTS_GETCONTENT_REQUEST',
    GETCONTENT_SUCCESS: 'PRODUCTS_GETCONTENT_SUCCESS',
    GETCONTENT_FAILURE: 'PRODUCTS_GETCONTENT_FAILURE',
}