import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Divider, Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

const UserPaperStyles = makeStyles((theme) => ({
  UserContainer: {
    // backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(4),
    textAlign: "center",
  },
  rowContainer: {
    padding: theme.spacing(0.5, 0),
  },
  fontColor: {
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    padding: theme.spacing(0, 0, 6),
  },
  fontBold: {
    fontWeight: 600,
  },
}));

export const UserPaper = ({ user }) => {
  const classes = UserPaperStyles();
  const { userData } = user;
  return (
    <React.Fragment>
      <Paper className={classes.UserContainer}>
        <Typography variant="h5" gutterBottom>
          User Information
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.rowContainer}
        >
          <Grid item>
            <Typography variant="body1" className={classes.fontBold}>
              Name :
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">{userData.name}</Typography>
          </Grid>
        </Grid>

        <Divider />

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.rowContainer}
        >
          <Grid item>
            <Typography variant="body1" className={classes.fontBold}>
              Email :
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">{userData.email}</Typography>
          </Grid>
        </Grid>

        <Divider />

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.rowContainer}
        >
          <Grid item>
            <Typography variant="body1" className={classes.fontBold}>
              Phone :
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">{userData.phone}</Typography>
          </Grid>
        </Grid>

        <Divider />

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.rowContainer}
        >
          <Grid item>
            <Typography variant="body1" className={classes.fontBold}>
              Date of Birth :
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">{userData.date_of_birth}</Typography>
          </Grid>
        </Grid>

        <Divider />

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.rowContainer}
        >
          <Grid item>
            <Typography variant="body1" className={classes.fontBold}>
              Address :
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">{userData.address}</Typography>
          </Grid>
        </Grid>

        <Divider />

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.rowContainer}
        >
          <Grid item>
            <Typography variant="body1" className={classes.fontBold}>
              Company ( Position ) :
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              {userData.company + " ( " + userData.role + " )"}
            </Typography>
          </Grid>
        </Grid>

        <Divider />

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.rowContainer}
        >
          <Grid item>
            <Typography variant="body1" className={classes.fontBold}>
              Education :
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">{userData.last_education}</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.rowContainer}
        >
          <Button component={RouterLink} to ={'/profile'} children="Edit Profile" fullWidth variant="contained" />
        </Grid>
      </Paper>
    </React.Fragment>
  );
};
