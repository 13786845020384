import { userConstants } from '../_constants';

export function user(state = {}, action) {
    switch (action.type) {
        case userConstants.GETALL_REQUEST:
            return {
                loading: true
            };
        case userConstants.GETALL_SUCCESS:
            return {
                items: action.users
            };
        case userConstants.GETALL_FAILURE:
            return {
                error: action.error
            };



        case userConstants.GETBYID_REQUEST:
            return {
                loading: true
            };
        case userConstants.GETBYID_SUCCESS:
            return {
                items: action.users
            };
        case userConstants.GETBYID_FAILURE:
            return {
                error: action.error
            };
        
        case userConstants.GETMYDATA_REQUEST:
            return {
                loading: true,
                items: {}
            };
        case userConstants.GETMYDATA_SUCCESS:
            return {
                items: action.user
            };
        case userConstants.GETMYDATA_FAILURE:
            return {
                error: action.error
            };
            


            
        case userConstants.EDITDATA_REQUEST:
            return {
                loading: true
            }
        case userConstants.EDITDATA_SUCCESS:
            return {
                user: action.users,
                loading:false
            }
        case userConstants.EDITDATA_FAILURE:
            return {
                loading: false,
                error: action.error
            }


        case userConstants.FORGOT_PASSWORD_REQUEST:
            return {
                isLoading: true,
            }
        case userConstants.FORGOT_PASSWORD_SUCCESS:
            return {
                isLoading: false,
                message: action.message
            }
        
        case userConstants.FORGOT_PASSWORD_FAILED:
        return {
            isLoading: false,
            error: action.error
        }

        case userConstants.RESET_PASSWORD_REQUEST:
            return {
                isLoading: true,
            }
        case userConstants.RESET_PASSWORD_SUCCESS:
            return {
                isLoading: false,
                message: "Please Login Using New Password"
            }
        case userConstants.RESET_PASSWORD_FAILED:
            console.log(action.error.message)
            return {
                isLoading: false,
                error: action.error.message
            }
        default:
            return state
    }
}