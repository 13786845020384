import {
	Grid, makeStyles,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions, transactionAction } from '../../_actions';
import { MyTrainingList } from "../../_components/Profile";


const TrainingPageStyle = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexGrow: 1,
	},
	image: {
		width: 200,
		height: 200,
	},
	img: {
		margin: 'auto',
		display: 'block',
		maxWidth: '100%',
		maxHeight: '100%',
	},
	paper: {
		padding: theme.spacing(2),
	},
	statsIcon: {
		marginRight: theme.spacing(1)
	},
	statsItem: {
		alignItems: 'center',
		display: 'flex'
	},
	boxProfile: {
		padding: theme.spacing(2)
	},
	trainingContainer: {
		margin: theme.spacing(0,16)
	  }

}));

export const MyTrainingPage = () => {
	const classes = TrainingPageStyle()
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(userActions.getMyData());
		dispatch(transactionAction.getByUser())
	}, [dispatch]);
	
	// const user = useSelector(state => state.user);
	const transaction = useSelector(state =>state.transaction)

	return (
		<React.Fragment>
			<Grid
				container
				direction="row"
				justifyContent="flex-start"
				alignItems="stretch"
				// spacing={2}
			>
				<Grid item xs={12} sm={12} md={12} className={classes.trainingContainer}>
					<MyTrainingList  transaction={transaction} />
					{/* <Paper className={classes.paper}>xs=12</Paper> */}
				</Grid>
			</Grid>

		</React.Fragment>
	)
}

