import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";

// import popupImg from "../../assets/images/popupImg.svg";
// import { ButtonSecondary } from "../form";

const style = makeStyles((theme) => ({
  dialogStyle: {
    "& > .MuiDialog-container > .MuiPaper-root": {
      // borderRadius: "20px",
      padding: theme.spacing(5, 8),
    },
  },
}));

export function DialogPopUpResponse({
  buttonClick,
  open,
  content,
  code,
  flag,
  textSuccess,
}) {
  const classes = style();

  const location = useLocation();

  let pathnames = location.pathname.split("/").filter((x) => x);

  const routeTo = `/${pathnames.slice(0, 2).join("/")}`;



  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={open}
      className={classes.dialogStyle}
    >
      <DialogTitle align="center">
        {/* <img src={popupImg} alt="newImagePopup" /> */}
      </DialogTitle>
      <DialogContent align="center">
        <Box
          textAlign="center"
          lineHeight="32px"
          fontSize="24px"
          fontWeight="fontWeightBold"
          color={code === "99" ? "text.error" : ""}
          mb={3}
        >
          {code === 201 && `Success ${flag}`}
          {(code === "99") && `Failed ${flag}`}
        </Box>
        <Box textAlign="center" lineHeight="24px" fontSize="14px">
          {(code !== 201) && `${content}`}
          {code === 201 && `${content}`}
        </Box>
      </DialogContent>

      <DialogActions>
        {code === 201 && (
          <Button
            fullWidth
            color="primary"
            children="Kembali"
            component={RouterLink}
            to={routeTo}
            onClick={() => buttonClick()}
          />
        )}
        {(code !== 201) && (
          <Button
            fullWidth
            color="primary"
            children="Kembali"
            onClick={() => buttonClick()}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}
