import React from "react";
import {
  Container,
  Typography,
  Box,
  makeStyles,
  Grid,
  Divider,
  Link,
} from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import PhoneIcon from "@material-ui/icons/Phone";

const contactPageStyle = makeStyles((theme) => ({
  image: {
    // background: "url(https://source.unsplash.com/bGeupv246bM) no-repeat center",
    backgroundSize: "cover",
    width: "700px",
    height: "500px",
    marginTop: "1rem",
    clipPath: "polygon(0 0, 56% 1%, 100% 100%, 39% 100%)",
  },
  map: {
    width: "500px",
    height: "200px",
    border: "0",
    padding: "1rem",
    margin: "auto",
  },
  icon: {
    marginRight: "0.5rem",
  },
}));
export function ContactUsPage() {
  console.log("asd")
  const classes = contactPageStyle();
  return (
    <>
      contact us
    </>
  );
}

