import { productsConstant } from "../_constants";
import { productServices } from "../_services"
import { alertActions } from "./";
// import { history } from '../_helpers'

export const productAction = {
    getAll,
    getById,
    getLesson,
    getContent
}

function getAll() {
    return dispatch => {
        dispatch(request());

        productServices.getAll()
            .then(
                products => {
                    dispatch(success(products))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            )
    }

    function request(products) { 
        return { type: productsConstant.GETALL_REQUEST, products } }
    function success(products) { 
        return { type: productsConstant.GETALL_SUCCESS, products } }
    function failure(error) { return { type: productsConstant.GETALL_FAILURE, error } }
}

function getById(id) {
    return dispatch => {
        dispatch(request(id))

        productServices.getById(id)
            .then(
                selected => {
                    dispatch(success(id, selected))
                    // history.push('/products/'+id);
                },
                error => dispatch(failure(id, error.toString()))
            )
    }

    function request(id) { return { type: productsConstant.GETBYID_REQUEST, id}}
    function success(id, selected) { return { type: productsConstant.GETBYID_SUCCESS,id , selected}}
    function failure(id, error) { return { type: productsConstant.GETBYID_FAILURE, id, error}}
}

function getLesson(id) {
    return dispatch => {
        dispatch(request(id))

        productServices.getLesson(id)
            .then(
                lesson => {
                    dispatch(success(id, lesson))
                    // history.push('/products/'+id);
                },
                error => dispatch(failure(id, error.toString()))
            )
    }

    function request(id) { return { type: productsConstant.GETLESSON_REQUEST, id}}
    function success(id, lesson) { return { type: productsConstant.GETLESSON_SUCCESS,id , lesson}}
    function failure(id, error) { return { type: productsConstant.GETLESSON_FAILURE, id, error}}
}

function getContent(id, contentId) {
    return dispatch => {
        dispatch(request(id, contentId))

        productServices.getContent(id,contentId)
            .then(
                content => {
                    dispatch(success(id, content))
                    // history.push('/products/'+id);
                },
                error => dispatch(failure(id, error.toString()))
            )
    }

    function request(id) { return { type: productsConstant.GETCONTENT_REQUEST, id}}
    function success(id, content) { return { type: productsConstant.GETCONTENT_SUCCESS,id , content}}
    function failure(id, error) { return { type: productsConstant.GETCONTENT_FAILURE, id, error}}
}