import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "@material-ui/lab";

import {
  Avatar,
  Button,
  TextField,
  Grid,
  Typography,
  makeStyles,
  InputAdornment,
  IconButton,
  Box,
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useFormik } from "formik";
import * as Yup from "yup";
import MuiPhoneNumber from "material-ui-phone-number";

import { authActions } from "../../_redux/actions";

const styles = makeStyles((theme) => ({
  root: {
    height: "93vh",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  errorText: {
    color: "red",
    textAlign: "center",
  },
}));

export function RegistrationContainer() {
  const classes = styles();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const auth = useSelector((state) => state.auth);
  //trigger git

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      password_confirmation: "",
      name: "",
      // date_of_birth: "",
      phone: "",
      address: "",
    },
    validationSchema: Yup.object({
      email: Yup.string(),
      password_confirmation: Yup.string().when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both password need to be the same"
        ),
      }),
    }),
    enableReinitialize: true,
    onSubmit: (ctx) => {
      dispatch(authActions.register(ctx));
    },
  });
  // console.log(formik.values);
  return (
    <div className={classes.paper}>
      <Typography component="h1" variant="h5">
        Registration
      </Typography>
      <Box mt={3}>
        {auth.message && <Alert severity="success" children={auth.message} />}
      </Box>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Email Address"
              variant="outlined"
              required
              fullWidth
              margin="normal"
              {...formik.getFieldProps("email")}
              helperText={
                <div className={classes.errorText}>
                  {auth.errors && auth.errors.email}
                </div>
              }
            />

            <TextField
              label="Full Name"
              variant="outlined"
              required
              fullWidth
              margin="normal"
              {...formik.getFieldProps("name")}
              helperText={
                <div className={classes.errorText}>
                  {auth.errors}
                </div>
              }
            />

            <TextField
              label="Password"
              variant="outlined"
              required
              fullWidth
              margin="normal"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...formik.getFieldProps("password")}
            />
            <TextField
              label="Confirm Password"
              variant="outlined"
              required
              fullWidth
              margin="normal"
              type={showRepeatPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password"
                      onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                    >
                      {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...formik.getFieldProps("password_confirmation")}
            />

            <Button
              type="submit"
              fullWidth
              margin="normal"
              variant="contained"
              color="primary"
              className={classes.submit}
              children={
                auth.isLoading ? (
                  <CircularProgress align="center" size="1.5rem" />
                ) : (
                  "Sign Up"
                )
              }
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
