import React from "react";
import {
  Paper,
  ButtonBase,
  makeStyles,
  Grid,
  Typography,
  Button,
  Box,
  Divider,
  Hidden,
  Grow,
} from "@material-ui/core";
import AccessTimeRoundedIcon from "@material-ui/icons/AccessTimeRounded";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import { history } from "../../_helpers";
import { differenceInDays } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  image: {
    width: 200,
    height: 200,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  box: {
    height: "100%",
    width: "100%",
  },
  container: {
    height: "10rem",
  },
  innerContainer: {
    // marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    // marginRight: theme.spacing(2),
  },
  item: {
    flex: 1,
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  paperHover: {
    position: "relative",
    "&:hover:before": {
      zIndex: "1",
      content: "' '",
      width: "0.3rem",
      height: "100%",
      display: "inline-block",
      background: "rgb(63,228,251)",
      background:
        "linear-gradient(69deg, rgba(63,228,251,0.87718837535014) 0%, rgba(82,70,252,0.9528186274509804) 100%)",
      animation: "$fadeIn .4s ease-in-out",
      position: "absolute",
      top: 0,
      left: 0,
    },
  },
}));

const LeftColumn = (productParam) => {
  const classes = useStyles();
  return (
    <ButtonBase className={classes.image}>
      <img
        className={classes.img}
        alt="training"
        src="https://via.placeholder.com/150"
        // src={productParam.url}
      />
    </ButtonBase>
  );
};

const MidColumn = (productParam) => {
  // console.log(productParam)

  return (
    <div>
      <Typography variant="h4" component="h1">
        <Box fontWeight="bold">{productParam.productName}</Box>
        <Divider></Divider>
      </Typography>
      <Typography color="textSecondary" variant="body2" gutterBottom>
        <Box fontStyle="italic" fontWeight="bold"></Box>
      </Typography>

      <Typography variant="body1" paragraph>
        {/* for hide long text */}
        <Box maxHeight={50} textOverflow="ellipsis" overflow="hidden">
          {productParam.productDescription}
        </Box>
      </Typography>

      <Grid item>
        <Box display="flex" alignItems="center" fontWeight="bold">
          <Typography variant="body1" color="primary">
            <Box>
              Pendaftaran : {productParam.eventRegistStart} -{" "}
              {productParam.eventRegistEnd}
            </Box>
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" fontWeight="bold">
          <Typography variant="body1" color="primary" gutterBottom>
            <Box>
              Waktu kegiatan : {productParam.eventStartDate}{" "}
              {productParam.eventStartTime}
            </Box>
          </Typography>
        </Box>

        <Typography variant="h5">
          <Box fontWeight="bold">{productParam.eventPrice}</Box>
        </Typography>
      </Grid>
    </div>
  );
};

const RightColumn = (productParam) => {
  let classes = useStyles();
  function handleGetProduct(id) {
    history.push("/products/" + id);
  }
  return (
    <div className={classes.innerContainer}>
      <Grid item xs={12} md container direction="row" spacing={2}>
        <Grid item xs className={classes.statsItem}>
          <AccessTimeRoundedIcon className={classes.statsIcon} color="action" />
          <Typography variant="body2" display="inline">
            {productParam.eventDays} Days
          </Typography>
        </Grid>

        <Grid item xs className={classes.statsItem}>
          <MenuBookRoundedIcon className={classes.statsIcon} color="action" />
          <Typography variant="body2" display="inline">
            {productParam.contents} Lessons
          </Typography>
        </Grid>

        <Grid item xs>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleGetProduct(productParam.id)}
          >
            Details
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export const ProductPaper = ({ className, product, ...rest }) => {
  const { contents } = product;
  const classes = useStyles();
  function ContentLength(contents) {
    // return Object.keys(contents).length;
  }

  const dateOnly = {
    day: "numeric",
    month: "long",
    weekday: "long",
    year: "numeric",
  };

  const timeOnly = {
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  };
  const currencyOption = {
    style: "currency",
    currency: "IDR",
    maximumSignificantDigits: 6,
  };

  const eventStartDate = new Intl.DateTimeFormat("id-ID", dateOnly).format(
    new Date(product.event_start)
  );
  const eventStartTime = new Intl.DateTimeFormat("id-ID", timeOnly).format(
    new Date(product.event_start)
  );

  const eventRegistStart = new Intl.DateTimeFormat("id-ID", {
    day: "numeric",
    month: "long",
  }).format(new Date(product.registration_start));

  const eventRegistEnd = new Intl.DateTimeFormat("id-ID", {
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(new Date(product.registration_end));

  const eventDays = differenceInDays(
    new Date(product.event_end),
    new Date(product.event_start)
  );

  const eventPrice = new Intl.NumberFormat("id-ID", currencyOption).format(
    product.event_price
  );

  return (
    <React.Fragment>
      {/* <Box className={classes.paperHover}> */}
      <Paper className={classes.paperHover}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          // className={classes.paperHover}
          spacing={2}
        >
          {/* left column */}
          <Grid item>
            <Hidden smDown sm={2} md={3}>
              <LeftColumn />
            </Hidden>
          </Grid>

          {/* mid column */}
          <Grid item xs={12} sm={8} md={6}>
            <MidColumn
              productName={product.name}
              productDescription={product.event_description}
              eventRegistStart={eventRegistStart}
              eventRegistEnd={eventRegistEnd}
              eventStartDate={eventStartDate}
              eventStartTime={eventStartTime}
              eventPrice={eventPrice}
            />
          </Grid>

          {/* right column */}
          <Grid item sm={2} md={2} lg={2} xl={2} container>
            <RightColumn
              eventDays={eventDays}
              contents={ContentLength(contents)}
              id={product.id}
            />
          </Grid>
        </Grid>
      </Paper>
      {/* </Box> */}
    </React.Fragment>
  );
};
