export const companyProfileDesc =
  "LLorem ipsum dolor sit amet, consectetur adipiscing elit. Non duis id massa LLorem ipsum dolor sit amet, consectetur adipiscing elit.  LLorem ipsum dolor sit amet, consectetur adipiscing elit. Non duis id massa Non duis id massa tortor commodo nunc turpis aliquet neque. Vulputate quis ultrices bibendum mus scelerisque egestas";

export const companyStaffProfile = [
  {
    name: "Jhon dee",
    position: "Profesional Trainer",
    imgUrl: "https://source.unsplash.com/c_GmwfHBDzk",
    desc:
      "I am a profesional trainer work with passion to resolve your problem",
  },
  {
    name: "Arthur",
    position: "Profesional Trainer",
    imgUrl: "https://source.unsplash.com/jmURdhtm7Ng",
    desc: "You can ask me anything to solve your problem !",
  },
  {
    name: "Dayana",
    position: "Profesional Trainer",
    imgUrl: "https://source.unsplash.com/pg_WCHWSdT8",
    desc: "You can ask me anything to solve your problem !",
  },
  {
    name: "Nissa",
    position: "Profesional Trainer",
    imgUrl: "https://source.unsplash.com/jgSAuqMmJUE",
    desc: "You can ask me anything to solve your problem !",
  },
];
