import {
  Grid, makeStyles, Box, Paper,
  TextField, Button
} from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { userActions } from '../../_actions';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  image: {
    width: 200,
    height: 200,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  paper: {
    padding: theme.spacing(2),
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  boxProfile: {
    padding: theme.spacing(2)
  }

}));

export const MyProfile = ({ user }) => {
  const classes = useStyles();
  user = user.items
  // console.log(user.email)
  // const user = useSelector(state => state.authentication.user)
  console.log(user);

  // const [user, setUser] = useState({
  // 	email: CurrentUser.email,
  // 	password: '',
  // 	passwordRepeat: '',
  // 	name: CurrentUser.name,
  // 	phone: CurrentUser.phone,
  // 	address: CurrentUser.address,
  // 	date_of_birth: CurrentUser.date_of_birth,
  // 	company: CurrentUser.company,
  // 	position: CurrentUser.position,
  // 	last_education: CurrentUser.last_education,
  // 	name_on_certificate: CurrentUser.name_on_certificate
  // });

  const [submitted, setSubmitted] = useState(false);
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [checkPassword, setCheckPassword] = useState(true);
  // const registering = useSelector(state => state.registration.registering);

  const dispatch = useDispatch();


  function handleChange(e) {
    // const { name, value } = e.target;
    // setUser(user => ({ ...user, [name]: value }));

  }

  const [disabled, setDisabled] = useState(true);

  function handleEdit() {
    setDisabled(!disabled);
  }

  function validatePassword() {

    if (user.password === user.passwordRepeat) {
      setCheckPassword(true);
    } else {
      setCheckPassword(false)
    }
  }


  function handleSubmit(e) {
    e.preventDefault();

    setSubmitted(true);
    if (user.email && user.password) {
      dispatch(userActions.register(user));
    }
  }

  return (
    <React.Fragment>

      <Paper square>
        {
          user &&
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.boxProfile}
          >
            <Box className={classes.image}>
              <img
                className={classes.img}
                alt="training"
                src="https://via.placeholder.com/150"
              />
            </Box>
            <form className={classes.form} onSubmit={handleSubmit} >
              <Grid container spacing={2}>

                {/*  Email Input */}
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    type="email"
                    value={user.email}
                    onChange={handleChange}
                    errorText={submitted && !user.email &&
                      <div className={classes.errorText}>Email is required</div>
                    }
                  />
                </Grid>

                {/*  Password Input */}
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    type="password"
                    value={user.password}
                    onKeyUp={validatePassword}
                    onChange={handleChange}
                    autoComplete="current-password"
                  />
                </Grid>
                {/*  Password Repeat Input */}
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="password_confirmation"
                    label="Password Confirmation"
                    name="password_confirmation"
                    type="password"
                    value={user.password_confirmation}
                    onChange={handleChange}
                    onKeyUp={validatePassword}
                    autoComplete="current-password"
                    helperText={checkPassword ? "" : "Password Doesn't Match"}
                    error={checkPassword ? false : true}
                  />
                </Grid>

                {/* Full Name */}
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="name"
                    label="Full Name"
                    name="name"
                    type="text"
                    defaultValue={user.name}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                </Grid>

                {/* Birthday */}
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="date_of_birth"
                    name="date_of_birth"
                    label="Birthdate"
                    type={showDatepicker ? "date" : "text"}
                    onFocus={() => setShowDatepicker(true)}
                    onBlur={() => setShowDatepicker(false)}
                    defaultValue={user.date_of_birth}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                </Grid>

                {/* Phone Input */}
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="phone"
                    name="phone"
                    label="Phone Number"
                    type="tel"
                    defaultValue={user.phone}
                    onChange={handleChange}
                    placeholder="+6281211112222"
                    disabled={disabled}
                  />
                </Grid>

                {/* Address Input */}
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    multiline
                    rows={4}
                    required
                    fullWidth
                    id="address"
                    name="address"
                    label="Address"
                    type="text"
                    defaultValue={user.address}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                </Grid>

                {/*  Company */}
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="company"
                    name="company"
                    label="Company / Organization"
                    type="text"
                    defaultValue={user.company}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                </Grid>

                {/*  Role */}
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="position"
                    name="position"
                    label="Role"
                    type="text"
                    defaultValue={user.position}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                </Grid>

                {/*  Education */}
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="last_education"
                    name="last_education"
                    label="Education"
                    type="text"
                    defaultValue={user.last_education}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                </Grid>

                {/* Full Name
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									fullWidth
									id="name_on_certificate"
									name="name_on_certificate"
									label="Name"
									type="text"
									defaultValue={user.name_on_certificate}
									onChange={handleChange}
									disabled={disabled}
								/>
							</Grid> */}
                <Grid item xs={6}>
                  <Button
                    // type="submit"
                    fullWidth
                    variant="contained"
                    // color="secondary"
                    className={classes.submit}
                    onClick={handleEdit}
                  >
                    {/* {registering && <span className="spinner-border spinner-border-sm mr-1"></span>} */}
										Edit
									</Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    {/* {registering && <span className="spinner-border spinner-border-sm mr-1"></span>} */}
                                Save
                        </Button>
                </Grid>
              </Grid>

            </form>
          </Grid>
        }
      </Paper>

    </React.Fragment>
  )
}
