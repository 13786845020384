import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { checkoutAction } from "../../_actions";
import { Grid } from "@material-ui/core";

const CheckoutPageStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(10, 15, 0),
    height: "75vmin",
    width: "100vw",
    textAlign: "center",
  },
  ProductContainer: {
    // backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(4),
    // textAlign: "center"
  },
  fontColor: {
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    padding: theme.spacing(0, 0, 6),
  },
  fontBold: {
    fontWeight: 600,
  },
}));

export const PricePaper = ({ checkout, paymentstatus }) => {
  const classes = CheckoutPageStyles();
  const { selectedProducts } = checkout;
  const userData = useSelector((state) => state.user.items);

  // const dateOption = {
  // 	day: "numeric",
  // 	month: "long",
  // 	year: "numeric",
  // 	weekday: "long",
  //   };

  const dateOnly = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };

  const currencyOption = {
    style: "currency",
    currency: "IDR",
    maximumSignificantDigits: 6,
  };

  const eventStart = new Intl.DateTimeFormat("id-ID", {
    day: "numeric",
    month: "long",
  }).format(new Date(selectedProducts.event_start));

  const eventEnd = new Intl.DateTimeFormat("id-ID", dateOnly).format(
    new Date(selectedProducts.event_end)
  );

  const eventPrice = new Intl.NumberFormat("id-ID", currencyOption).format(
    selectedProducts.event_price
  );

  const dispatch = useDispatch();

  function handleSubmit(e) {
    e.preventDefault();

    let invParams = {
      // payer_email: userData.user_email,
      user_id: userData.id,
      sku: selectedProducts.sku,
      description: selectedProducts.name,
      amount: selectedProducts.event_price,
      // url_thankyou: "http://localhost:3000/"
    };

    dispatch(checkoutAction.invoiceReq(invParams));
  }

  return (
    <React.Fragment>
      <Paper className={classes.ProductContainer}>
        <Typography variant="h5">Product Information</Typography>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          // alignItems="stretched"
          spacing={2}
        >
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              Product :
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.fontBold}>
              {selectedProducts.name}
            </Typography>
            <Typography variant="caption">{selectedProducts.sku}</Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          // alignItems="stretched"
          spacing={2}
        >
          <Grid item xs={6}>
            <Typography variant="body2" gutterBottom>
              Event Date
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body2"
              className={classes.fontBold}
              gutterBottom
            >
              {eventStart} - {eventEnd}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          // alignItems="stretched"
          spacing={2}
        >
          <Grid item xs={6}>
            <Typography variant="body2" gutterBottom>
              Event Address
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body2"
              className={classes.fontBold}
              gutterBottom
            >
              {selectedProducts.event_address}
            </Typography>
          </Grid>
        </Grid>

        <Divider></Divider>

        {paymentstatus != "PAID" && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {eventPrice}
            </Grid>
            <Grid item xs={12}>
              <React.Fragment>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  fullWidth
                >
                  CONFIRM REGISTRATION
                </Button>
              </React.Fragment>
            </Grid>
          </Grid>
        )}
      </Paper>
    </React.Fragment>
  );
};
