import { authHeader } from '../_helpers';
import { config } from "./config";

export const userService = {
    login,
    logout,
    register,
    getAll,
    getById,
    update,
    getMyData,
    delete: _delete,
    forgotPassword,
    resetPassword,
};

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*/*',
            'X-Requested-With': 'XMLHttpRequest'
         },
         body: JSON.stringify({ email, password })
    };

    return fetch(`${config.apiUrl+config.login}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            sessionStorage.setItem('user', JSON.stringify(user));

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/user/${id}`, requestOptions).then(handleResponse);
}

function getMyData() {
    const requestOptions={
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/user`, requestOptions).then(handleResponse);
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*/*',
            'X-Requested-With': 'XMLHttpRequest'
         },
        body: JSON.stringify(user)
    };
    return fetch(`${config.apiUrl+config.register}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${config.apiUrl}/user/${user.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function forgotPassword(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*/*',
            'X-Requested-With': 'XMLHttpRequest'
        },
        body: JSON.stringify(email)
    }

    return fetch(`${config.apiUrl}/password/create`, requestOptions)
        .then(handleResponse)
}

function resetPassword(body) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*/*',
            'X-Requested-With': 'XMLHttpRequest'
        },
        body: JSON.stringify(body)
    }

    return fetch(`${config.apiUrl}/password/reset-pass`, requestOptions)
        .then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                // location.reload(true);
            }
            const error = {
                detail: data.errors,
                message: data.message,
                status: response.status}
            return Promise.reject(error);
        }
        return data;
    });
}