import {
  Drawer,
  List, 
  ListItem, makeStyles, Toolbar, 
  CircularProgress, ListItemText, Paper,
  Typography, Divider
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useParams, useRouteMatch, Route} from 'react-router';
import { history } from "../../_helpers";
import { productAction } from '../../_actions';
import parse from "html-react-parser";


const TrainingDetailStyles = makeStyles((theme) => ({
  drawer: {
    zIndex: 1100,
  },
  paperContent: {
    marginLeft: "14rem",
    zIndex: 1100,
    padding:theme.spacing(2)
  },
  paperMenu: {
    maxWidth: "12rem",
    // padding: theme.spacing(2)
  },
}));

export const MyTrainingDetailPage = () => {
  const classes = TrainingDetailStyles()
  const isLoading = useSelector(state => state.lesson.loading)  
  // const contents = useSelector(state => state.lesson.contents)  
  const { id } = useParams();
  let { url } = useRouteMatch();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(productAction.getLesson(id));
  }, [id, dispatch]);

  return (
    <React.Fragment>
      {
        isLoading ? (
          <span
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <CircularProgress align="center" />
          </span>
        ) : (
          <MyTrainingMenu />  
        )
      }
      <Switch>
        <Route exact path={`${url}`}>
          <Paper className={classes.paperContent}>
            <Typography>
              Please select the title
            </Typography>
          </Paper>
        </Route>
        <Route exact path={`${url}/:contentId`}>
          <ContentPaper  />
        </Route>
      </Switch>

    </React.Fragment>
  )
}

export const MyTrainingMenu = () => {
  const {contents} = useSelector(state => state.lesson)  
  const classes = TrainingDetailStyles()
  // console.log(lesson.contents)

  return (
    <React.Fragment>
      <Drawer variant="permanent" className={classes.drawer}>
        <Toolbar />
        <List className={classes.paperMenu}>
          { contents &&
            contents.map(
              (data) => {

                return(
                  <React.Fragment>
                    <ListContent key={data.id} data={data} />
                    <Divider />
                  </React.Fragment>
                )
              }
            )
          }
        </List>
      </Drawer>
    </React.Fragment>
  )
}

export const ListContent = ({data}) => {
  let { url } = useRouteMatch();
  function handleGetContent(contentId) {
    history.push(url+"/"+contentId)
  }
  return(
    <React.Fragment>
      <ListItem button onClick={() => handleGetContent(data.id)} >
        <ListItemText>{data.title}</ListItemText>
      </ListItem>
    </React.Fragment>
  )
}

export const ContentPaper = ({contents}) => {
  const classes = TrainingDetailStyles()
  const isLoading = useSelector(state => state.content.loading)  
  const {idProduct} = useSelector(state=>state.lesson)
  const {items} = useSelector(state=>state.content)
  const {contentId} = useParams()
  const dispatchContent = useDispatch();
  console.log(idProduct)
  let { url } = useRouteMatch();
  useEffect(() => {
    dispatchContent(productAction.getContent(idProduct,contentId));
  }, [url]);
  console.log(items)
  return(
    <React.Fragment>
      {
        isLoading? 
        (
          <span
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <CircularProgress align="center" />
          </span>
        )
        :
        <Paper className={classes.paperContent}>
          <Typography variant="h4">
            {
              items&& items.title
            }
          </Typography>
          <Divider />
          <Typography variant="body">
            {
              items&& parse(items.description)
            }
          </Typography>
        </Paper>
      }
    </React.Fragment>
  )
} 