import React, { useState, useEffect } from "react";
import {
  Grid,
  makeStyles,
  Box,
  Paper,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useFormik } from "formik";
import * as Yup from "yup";
import MuiPhoneNumber from "material-ui-phone-number";
import { Typography } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { InputTextField } from "../../components/Form";
import { Dropdown } from "../../components/Form";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  image: {
    width: 200,
    height: 200,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  boxProfile: {
    padding: theme.spacing(2),
  },
  boxSubProfile: {
    padding: theme.spacing(0, 1),
  },
  separator: {
    margin: theme.spacing(2),
    borderBottom: "1px solid #DADEE3",
  },

  paperV2: {
    width: "100%",
    boxShadow: "0px 2px 5px 0px #0000001A",
    borderRadius: "5px",
  },
  wrapper: {
    padding: theme.spacing(3, 2),
    borderRadius: "15px",
  },
  title: {
    padding: theme.spacing(0, 2),
    fontWeight: "700",
    fontSize: "18px",
    // lineHeight: "30px",
  },
  formStyle: {
    display: "flex",
    flexFlow: "wrap",
    width: "600px",
  },
  formInput: {
    fontWeight: "600",
    borderRadius: "10px",
    "& .MuiFormLabel-root ": {
      left: "unset",
    },
    "& > .MuiFormControl-root": {
      width: "100%",
    },
  },
  formDropdown: {
    width: "80%",
    "& > .MuiInputBase-root": {},
  },
}));

export function MyProfileContainer({ profileValue, formActionDispatcher,isSubmitted }) {
  const classes = useStyles();
  // const history = useHistory();

  const [isEdit, setisEdit] = useState(false);

  const formik = useFormik({
    initialValues: {
      ...profileValue,
      // email: profileValue.email,
      // name: profileValue.name,
      // nik: profileValue.nik,
      // date_of_birth: profileValue.date_of_birth,
      // gender: profileValue.gender,
      // nationality: profileValue.nationality,
      // address: profileValue.address,
      // phone: profileValue.phone,
      // last_education: profileValue.last_education,

      // company: profileValue.company,
      // role: profileValue.role,
      // company_address: profileValue.company_address,
      // company_phone: profileValue.company_phone,
    },
    validationSchema: Yup.object({}),
    enableReinitialize: true,
    onSubmit: (ctx) => {
      if (typeof formActionDispatcher == "function") {
        formActionDispatcher(ctx);
        // history.push('/profile')
        setisEdit(false);
      }
    },
  });

  const optionGender = [
    {
      value: "L",
      label: "Laki-Laki",
    },
    {
      value: "P",
      label: "Perempuan",
    },
  ];

  const optionLastEdu = [
    {
      value: "SMA / SMK",
      label: "SMA / SMK",
    },
    {
      value: "D1",
      label: "Diploma I",
    },
    {
      value: "D2",
      label: "Diploma II",
    },
    {
      value: "D3",
      label: "Diploma 3",
    },
    {
      value: "S1",
      label: "S1",
    },
    {
      value: "S2",
      label: "S2",
    },
    {
      value: "S3",
      label: "S3",
    },
  ]
  return (
    <Paper className={classes.paperV2}>
      <Grid container className={classes.wrapper}>
        <Grid item xs={12} md={12}>
          <Box className={classes.title} mb={3}>
            Profile
          </Box>
        </Grid>
        <form onSubmit={formik.handleSubmit} className={classes.formStyle}>
          <Grid xs={12} md={6}>
            <Box px={2} mb={4}>
              <FormControl
                className={`${classes.FormControl}`}
                variant="outlined"
              >
                <InputTextField
                  label="Email"
                  type="text"
                  required
                  fullWidth
                  {...formik.getFieldProps("email")}
                  disabled={true}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={2} mb={4}>
              <FormControl
                className={`${classes.FormControl}`}
                variant="outlined"
              >
                <InputTextField
                  label="Full Name"
                  type="text"
                  required
                  fullWidth
                  {...formik.getFieldProps("name")}
                  disabled={!isEdit}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={2} mb={4}>
              <FormControl
                className={`${classes.FormControl}`}
                variant="outlined"
              >
                <InputTextField
                  label="NIK"
                  type="text"
                  required
                  fullWidth
                  {...formik.getFieldProps("nik")}
                  disabled={!isEdit}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={2} mb={4}>
              <FormControl
                className={`${classes.FormControl}`}
                variant="outlined"
              >
                <InputTextField
                  label="Date of Birth"
                  type="text"
                  required
                  fullWidth
                  {...formik.getFieldProps("date_of_birth")}
                  disabled={true}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={2} mb={4}>
              <FormControl
                className={`${classes.formDropdown} ${classes.formControl}`}
                variant="outlined"
              >
                <InputLabel>
                  Jenis Kelamin
                  <Box component="span" color="text.error" children=" * " />
                </InputLabel>
                <Dropdown
                  {...formik.getFieldProps("gender")}
                  label="Jenis Kelamin"
                  option={optionGender}
                  required={true}
                  value={formik.values.gender}
                  disabled={!isEdit}
                />
                {/* <Select
                  label="Jenis Kelamin"
                  value={formik.values.gender}
                  required
                  disabled={!isEdit}
                >
                  <MenuItem value="L" children="Laki-Laki" />
                  <MenuItem value="P" children="Perempuan" /> */}
                {/* </Select> */}
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={2} mb={4}>
              <FormControl
                className={`${classes.FormControl}`}
                variant="outlined"
              >
                <InputTextField
                  label="Nationality"
                  type="text"
                  required
                  fullWidth
                  {...formik.getFieldProps("nationality")}
                  disabled={!isEdit}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={2} mb={4}>
              <FormControl
                className={`${classes.FormControl}`}
                variant="outlined"
              >
                <InputTextField
                  label="Address"
                  type="text"
                  required
                  fullWidth
                  {...formik.getFieldProps("address")}
                  disabled={!isEdit}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={2} mb={4}>
              <FormControl
                className={`${classes.FormControl}`}
                variant="outlined"
              >
                <InputTextField
                  label="Phone"
                  type="text"
                  required
                  fullWidth
                  {...formik.getFieldProps("phone")}
                  disabled={true}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={2} mb={4}>
              <FormControl
                className={`${classes.formDropdown} ${classes.formControl}`}
                variant="outlined"
              >
                <InputLabel>
                  Last Education
                  <Box component="span" color="text.error" children=" * " />
                </InputLabel>
                <Dropdown 
                  {...formik.getFieldProps("last_education")}
                  label="Last Education"
                  option={optionLastEdu}
                  required={true}
                  value={formik.values.last_education}
                  disabled={!isEdit}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={2} mb={4}>
              <FormControl
                className={`${classes.FormControl}`}
                variant="outlined"
              >
                <InputTextField
                  label="NPWP"
                  type="text"
                  // required
                  fullWidth
                  {...formik.getFieldProps("npwp_pribadi")}
                  disabled={!isEdit}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={2} mb={4}>
              <FormControl
                className={`${classes.FormControl}`}
                variant="outlined"
              >
                <InputTextField
                  label="Golongan(for PNS)"
                  type="text"
                  fullWidth
                  {...formik.getFieldProps("golongan")}
                  disabled={!isEdit}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box className={classes.title} mb={3}>
              Work Profile
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={2} mb={4}>
              <FormControl
                className={`${classes.FormControl}`}
                variant="outlined"
              >
                <InputTextField
                  label="Company"
                  type="text"
                  required
                  fullWidth
                  {...formik.getFieldProps("company")}
                  disabled={!isEdit}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={2} mb={4}>
              <FormControl
                className={`${classes.FormControl}`}
                variant="outlined"
              >
                <InputTextField
                  label="Role"
                  type="text"
                  required
                  fullWidth
                  {...formik.getFieldProps("role")}
                  disabled={!isEdit}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={2} mb={4}>
              <FormControl
                className={`${classes.FormControl}`}
                variant="outlined"
              >
                <InputTextField
                  label="Company Address"
                  type="text"
                  required
                  fullWidth
                  {...formik.getFieldProps("company_address")}
                  disabled={!isEdit}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={2} mb={4}>
              <FormControl
                className={`${classes.FormControl}`}
                variant="outlined"
              >
                <InputTextField
                  label="Company Phone"
                  type="text"
                  required
                  fullWidth
                  {...formik.getFieldProps("company_phone")}
                  disabled={!isEdit}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={2} mb={4}>
              <FormControl
                className={`${classes.FormControl}`}
                variant="outlined"
              >
                <InputTextField
                  label="NPWP Perusahaan"
                  type="text"
                  // required
                  fullWidth
                  {...formik.getFieldProps("npwp_perusahaan")}
                  disabled={!isEdit}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* {} */}
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              // fullWidth
              variant="contained"
              // color="secondary"
              className={classes.submit}
              onClick={() => setisEdit(!isEdit)}
            >
              {/* {registering && <span className="spinner-border spinner-border-sm mr-1"></span>} */}
              Edit
            </Button>
          </Grid>
          <Grid item xs={8} md={4}>
            <Button
              type="submit"
              // fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isSubmitted}
            >
              {/* {registering && <span className="spinner-border spinner-border-sm mr-1"></span>} */}
              Save
            </Button>
          </Grid>
        </form>
      </Grid>
    </Paper>
  );
}
