import React, { useState, useEffect } from "react";
import { useLocation, Link as RouterLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Avatar,
  Button,
  TextField,
  Box,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab/";
import { useFormik } from "formik";
import * as Yup from "yup";

import logo from "../../LOGO.png";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";


import { authActions } from "../../_redux/actions";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    margin: theme.spacing(3, 0, 2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logoDisplay: {
    // margin: theme.spacing(2, 1),
    display: "block",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "auto%",
  },
}));

export function LoginContainer() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const auth = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: Yup.object({
      email: Yup.string(),
      password: Yup.string()
        .min(6, "Format password salah")
        .max(32, "Password terlalu panjang")
    }),
    onSubmit: (ctx) => {
      dispatch(authActions.login(ctx))
    },
  });
  const history = useHistory()
  useEffect(() => {
    //TODO: pindahin ke routes, jadiin redirect kalau ini public pages
    if (auth.isAuthenticated === true) {
      history.push("/home");
    }
  }, [auth]);

  return (
    <div className={classes.paper}>
      <img src={logo} alt="LOGO-LPK" className={classes.logoDisplay}></img>
      <Box mt={3} mb={3}>
        <Typography component="h1" variant="h5" children={"Sign in"} />
      </Box>

      <Box>
        {(auth.status == "422" || auth.status == "401"  ) && <Alert severity={"error"} children={"Wrong Credentials"} />}
      </Box>

      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <TextField
          label="Email Address"
          variant="outlined"
          margin="normal"
          fullWidth
          required
          {...formik.getFieldProps("email")}
        />
        <TextField
          label="Password"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type={showPassword ? "text" : "password"}
          {...formik.getFieldProps("password")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {formik.errors.password && <Alert severity={"error"} children={formik.errors.password} />}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Login
        </Button>
        <Grid container>
          <Grid item xs>
            <RouterLink to="/forgotpassword" variant="body2" children="Forgot Password?" />
          </Grid>
          <Grid item>
            <RouterLink to="/register" variant="body2">
              {"Don't have an account? Sign Up"}
            </RouterLink>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
