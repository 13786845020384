import React from "react";
import { Select, MenuItem, Typography, withStyles } from "@material-ui/core";

const InputSelect = withStyles({
  root: {
    "&.Mui-disabled": {
      background: "#F4F6F9",
      "& p": {
        color: "#000",
      },
    },
  },
})(Select);

export function Dropdown(props) {
  return (
    <>
      {typeof props.value != "undefined" ? (
        <InputSelect value={props.value} {...props} required={false}>
          {props.option.map((data, index) => (
            <MenuItem key={index} value={data.value ? data.value : data}>
              <Typography
                variant="body1"
                children={data.label ? data.label : data}
              />
            </MenuItem>
          ))}
        </InputSelect>
      ) : (
        ""
      )}
    </>
  );
}
