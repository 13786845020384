import React from "react";
import {
  Grid,
  Box,
  Typography,
  Paper,
  Divider,
  Container,
  makeStyles,
  Avatar,
} from "@material-ui/core";

import { companyProfileDesc, companyStaffProfile } from "../../_services";

const useStyles = makeStyles((theme) => ({
  Header: {
    textAlign: "center",
    textTransform: "uppercase",
    height: "auto%",
    width: "100%",
  },

  CompanyImage: {
    minWidth: "200px",
    minHeight: "300px",
    background: "url(https://source.unsplash.com/QdAAasrZhdk) no-repeat center",
    backgroundSize: "cover",
    borderRadius: "20px",
    marginTop: theme.spacing(1),
  },
  PaperText: {
    borderRadius: "10px",
    backgroundColor: "#3A3C41",
    color: "#F3F3F3",
    padding: theme.spacing(1, 2, 2, 2),
    marginTop: theme.spacing(1),
    Width: "400px",
  },
  avatar: {
    minHeight: "135px",
    minWidth: "135px",
  },
}));

function AboutPage() {
  const classes = useStyles();

  return (
    <Container maxWidth>
      {/* AboutPage Content Container */}
      <Grid container direction="row" justifyContent="center" alignItems="center">
        {/* AboutPage Header */}
        <Grid item xs={12}>
          <Typography component="h1" variant="h2">
            <Box textAlign="center" p={1} fontWeight={300}>
              About
            </Box>
          </Typography>
          <Divider variant="middle" />
        </Grid>
        {/* Photo company*/}
        <Grid item xs={12} md={6}>
          <Box width="90%" mx="auto" mt={4} mb={3}>
            <Paper elevation={6} className={classes.CompanyImage}></Paper>
          </Box>
        </Grid>
        {/* Description company */}
        <Grid item xs={12} md={6}>
          <Box mx="auto" width="85%" mt={4}>
            <Paper className={classes.PaperText} elevation={1}>
              <Typography component="div" variant="h4" gutterBottom>
                <Box fontWeight="fontWeightBold">
                  LPK Elektromedik Solutions
                </Box>
              </Typography>
              <Typography component="div" variant="body1" gutterBottom>
                <Box fontWeight="fontWeightRegular" textAlign="justify">
                  {companyProfileDesc}
                </Box>
              </Typography>
            </Paper>
          </Box>
        </Grid>
        {/* Company staff profile */}

        {companyStaffProfile.map((staff, index) => (
          <Grid
            xs={12}
            md={3}
            container
            spacing={1}
            style={{ marginTop: "1rem" }}
            key={index}
          >
            <Grid
              item
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              direction="column"
              container
              lg
            >
              <Avatar
                alt={staff.name}
                src={staff.imgUrl}
                sizes="medium"
                className={classes.avatar}
              />
              <Box mt={1} textAlign="center">
                <Typography component="h1" variant="h5" gutterbottom>
                  <Box fontWeight="bold">{staff.name}</Box>
                  <Box fontWeight="medium" fontSize={16} mb={1}>
                    {staff.position}
                  </Box>
                </Typography>
                <Typography component="p" variant="subtitle1">
                  <Box fontWeight="fontWeightLight"> {staff.desc}</Box>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ))}

        {/* Grid ends container */}
      </Grid>
    </Container>
  );
}

export default AboutPage;
