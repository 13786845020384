import React from 'react'
import {
  Grid,
  makeStyles,
  CssBaseline
} from '@material-ui/core';
import { Jumbotron } from './Jumbotron';
import ProductList from '../ProductsList';

const LandingPageStyles = makeStyles((theme) => ({
  jumbotron: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2,0,2),
    // height: "78vmin",
    width: "100vw",
    textAlign: "center"
  },
  productList: {
    // backgroundColor: theme.palette.secondary.main,
  }
}));

export const Layout = (props) => {
  const classes = LandingPageStyles()

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid 
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item className={classes.jumbotron}>
          <Jumbotron />
        </Grid>
        <Grid item className={classes.productList}>
          <ProductList />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}


