import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { alert } from './alert.reducer';
import { products, selectedProducts, lesson, content } from "./products.reducer";
import { checkout } from "./checkout.reducer";
import { transaction } from "./transaction.reducer";
import { user } from "./user.reducer";

const rootReducer = combineReducers({
    authentication,
    registration,
    selectedProducts,
    products,
    alert,
    checkout,
    transaction,
    user,
    lesson,
    content
});

export default rootReducer;