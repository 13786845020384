import React, { useEffect } from "react";
import { Route, Switch, Router, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { history } from "./_helpers";
import PrivateRoute from "./components/PrivateRoute";

import { AppsBarContainer } from "./containers/AppsBarContainer";
import {
  LoginPage,
  ForgotPassword,
  ResetPassword,
  ActivationPage,
} from "./pages/Auth";

//new 
import { RegistrationPage } from "./pages/Registration/RegistrationPage";
import { LessonPage } from "./pages/Lesson";
import { ProductListPage as ProductListPageV2,
         ProductDetailPage as ProductDetailV2
} from "./pages/ProductV2";
import { MyProfilePage } from "./pages/Profile";

import { authActions } from "./_redux/actions";

//legacy
import { MyTrainingPage, MyTrainingDetailPage } from "./pages/MyTraining";
import { CheckoutPage } from "./pages/Checkout";
import { AppsBar } from "./_components/AppBar";
import ProductList from "./pages/ProductsList";
import ProductDetailPage from "./pages/ProductDetail";
import HomePage from "./pages/Home";
import { ContactPage } from "./pages/Contact";
import { LandingPage } from "./pages/Landing";

export default function Routes() {
  const appState = useSelector((state) => state.appState);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.checkAuth());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    let storageListener = window.addEventListener("storage", () => {
      dispatch(authActions.checkAuth());
    });
    return () => storageListener && storageListener.removeEventListener();
  }, []);

  return appState.isAppReady ? (
    <>
      <Router history={history}>
        {/* <AppsBar position="fixed" /> */}
        <AppsBarContainer position="fixed" />
        <Switch>
          <Route exact path="/" component={() =><LandingPage /> } />
          <Route exact path="/login" component={() => <LoginPage />} />
          <Route
            exact
            path="/forgotpassword"
            component={() => <ForgotPassword />}
          />
          <Route
            exact
            path="/resetpassword/:token"
            component={() => <ResetPassword />}
          />
          <Route
            exact
            path="/register"
            component={() => <RegistrationPage />}
          />
          <Route exact path="/products" component={() => <ProductList />} />
          <Route
            exact
            path="/products/:id"
            component={() => <ProductDetailV2 />}
          />
          <Route
            exact
            path="/contact"
            component={() => <ContactPage />}
          />
          <Route
            exact
            path="/activate/:key"
            component={() => <ActivationPage />}
          />
          <PrivateRoute
            exact
            path="/profile"
            component={() => <MyProfilePage />}
          />
          <PrivateRoute
            exact
            path="/mytraining"
            component={() => <MyTrainingPage />}
          />
          {/* <PrivateRoute
            path="/products/:id/lesson"
            component={() => <MyTrainingDetailPage />}
          /> */}
          <PrivateRoute exact path="/home" component={() => <HomePage />} />
          <PrivateRoute
            exact
            path="/checkout/:id"
            component={() => <CheckoutPage />}
          />
          <PrivateRoute
            exact={false}
            path="/products/:id/lesson"
            component={() => <LessonPage />}
          />
        </Switch>
      </Router>
    </>
  ) : (
    "menyiapkan aplikasi" //TODO: change to component / page
  );
}
