import React, { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { MyProfile } from "../../_components/Profile";
import { userActions } from "../../_actions";
import { authActions } from "../../_redux/actions/auth.actions";

import { MyProfileContainer } from "../../containers/Auth";
import { useHistory } from "react-router";
import { DialogPopUpResponse } from "../../components/Core";

const ProfileStylePage = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  image: {
    width: 200,
    height: 200,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  paper: {
    padding: theme.spacing(2),
  },
  frame: {
    maxWidth: "60rem",
  },
}));

export function MyProfilePage() {
  const classes = ProfileStylePage();
  const dispatch = useDispatch();
  const history = useHistory();

  const { data, isSubmitted,responseCode, responseDesc, responseData } = useSelector((state) => state.profile);

  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    dispatch(authActions.getProfile());
    if(isSubmitted) {
      setDialog(isSubmitted)
    }
  }, [isSubmitted]);

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          // className={classes.frame}
        >
          <MyProfileContainer 
            profileValue={data} 
            formActionDispatcher={(ctx) => dispatch(authActions.editProfile(ctx))}
            isSubmitted={isSubmitted}
          />
        </Grid>
      </Grid>
      <DialogPopUpResponse 
        open={dialog}
        buttonClick={()=>setDialog(!dialog)}
        flag="Edit"
        content={responseDesc}
        code={responseCode}
        textSuccess
      />
    </React.Fragment>
  );
}
