export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));

    if (user) {
        return { 
            'Access-Control-Allow-Origin': '*/*',
            'Authorization': 'Bearer ' + user,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        };
    } else {
        return {};
    }
}