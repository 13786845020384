import { lessonConstants } from "../constants";

const initialState = {};

export function lessonV2(state = initialState, action) {
  switch (action.type) {
    case lessonConstants.GET_LESSON_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    case lessonConstants.GET_LESSON_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case lessonConstants.GET_LESSON_FAILED:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export function contentV2(state = initialState, action) {
  switch (action.type) {
    case lessonConstants.GET_LESSON_CONTENT_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    case lessonConstants.GET_LESSON_CONTENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case lessonConstants.GET_LESSON_CONTENT_FAILED:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
