import { authConstants } from "../constants";
import { authService } from "../../_services/authService";
import { appStateActions } from "./appState.actions";
import { differenceInMinutes } from "date-fns/esm";

const authServiceInstance = new authService();

export const authActions = {
  login,
  saveToken,
  checkAuth,
  removeToken,
  forgotPass,
  // resetPass,
  register,
  getProfile,
  editProfile,
  activate,
};

function login(payload) {
  let { email, password, remember } = payload;

  return async (dispatch) => {
    dispatch({
      type: authConstants.LOGIN_REQUEST,
      payload: { isLoading: true },
    });

    const { data, isError, message } = await authServiceInstance.login({
      email,
      password,
      remember,
    });

    if (isError) {
      return dispatch({
        type: authConstants.LOGIN_FAILED,
        payload: {
          isLoading: false,
          isError: true,
          status: message ? message.status : "",
          statusText: message ? message.statusText : "",
          data: message.data.message,
        },
      });
    } else {
      dispatch(saveToken(data.access_token, data.expires_at));
      return dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: {
          isAuthenticated: true,
          isError: false,
          isLoading: false,
          responseCode: data.responseCode,
          responseDesc: data.responseDesc,
          responseData: data.responseData,
        },
      });
    }
  };
}

function saveToken(token, expires_at) {
  localStorage.setItem("auth_token", JSON.stringify(token));
  localStorage.setItem("user", JSON.stringify(token));
  localStorage.setItem("expires_at", expires_at);

  return {
    type: authConstants.LOGIN_STORE_TOKEN,
    payload: {
      isAuthenticated: true,
      authToken: token,
      expiredAt: expires_at
    },
  };
}

function checkAuth() {
  const authToken = localStorage.getItem("auth_token");
  const expiredAt = localStorage.getItem("expires_at");
  const currentTime = new Date().toISOString();

  return (dispatch) => {

    let check = differenceInMinutes(
      new Date(expiredAt),
      new Date(currentTime)
    )
    console.log(check)
    if (check <= 0 ) {
      // if (check >= 430 && getRememberMe === false) {
      localStorage.removeItem("auth_token");
      localStorage.removeItem("expires_at");
      localStorage.removeItem("user");

      dispatch(appStateActions.isAppReady());
      return dispatch({
        type: authConstants.CHECK_LOCAL_TOKEN,
        payload: {
          isAuthenticated: false,
          isLoading: false,
          expiredAt: 0,
        },
      });
    } else if (authToken) {
      dispatch({
        type: authConstants.CHECK_LOCAL_TOKEN,
        payload: {
          isAuthenticated: true,
          authToken: authToken,
          expiredAt: expiredAt,
        },
      });
      return dispatch(appStateActions.isAppReady());
    } else {
      dispatch({ type: authConstants.CHECK_LOCAL_TOKEN });
      return dispatch(appStateActions.isAppReady());
    }
  };
}

function removeToken() {
  localStorage.removeItem("auth_token");
  localStorage.removeItem("expired_at");
  localStorage.removeItem("users");

  return {
    type: authConstants.LOGIN_REMOVE_TOKEN,
    payload: {
      isAuthenticated: false,
      authToken: "",
    },
  };
}

function forgotPass(payload) {
  let { email } = payload;
  return async (dispatch) => {
    dispatch({
      type: authConstants.FORGOT_PASS_REQUEST,
      payload: { isLoading: true },
    });

    const { data, isError, message } = await authServiceInstance.forgotPass(
      email
    );

    if (isError) {
      dispatch({
        type: authConstants.FORGOT_PASS_FAILED,
        payload: {
          isError: true,
          isLoading: false,
          status: message ? message.status : "",
          statusText: message ? message.statusText : "",
        },
      });
    } else {
      dispatch({
        type: authConstants.FORGOT_PASS_SUCCESS,
        payload: {
          isError: false,
          isLoading: false,
          email: email,
          forgotPass: true,
        },
      });
    }
  };
}

function resetPass() {}

function register(payload) {
  return async (dispatch) => {
    dispatch({
      type: authConstants.REGISTER_REQUEST,
      payload: {
        isLoading: true,
      },
    });

    const { data, isError, message } = await authServiceInstance.register(
      payload
    );

    if (isError) {
      dispatch({
        type: authConstants.REGISTER_FAILED,
        payload: {
          isError: true,
          isLoading: false,
          status: message ? message.status : "",
          statusText: message ? message.statusText : "",
          errors: message ? message.data.errors : {},
        },
      });
    } else {
      dispatch({
        type: authConstants.REGISTER_SUCCESS,
        payload: {
          isError: false,
          isLoading: false,
          message: data.message,
        },
      });
    }
  };
}

function getProfile() {
  return async (dispatch) => {
    dispatch({
      type: authConstants.PROFILE_REQUEST,
      payload: {
        isLoading: true,
      },
    });

    const { data, isError, message } = await authServiceInstance.profile();

    if (isError) {
      dispatch({
        type: authConstants.PROFILE_FAILED,
        payload: {
          isError: true,
          isLoading: false,
          status: message ? message.status : "",
          statusText: message ? message.statusText : "",
          errors: message ? message.data.errors : {},
        },
      });
    } else {
      dispatch({
        type: authConstants.PROFILE_SUCCESS,
        payload: {
          isError: false,
          isLoading: false,
          data: data
        },
      });
    }
  };
}

function editProfile(payload) {
  return async (dispatch) => {
    dispatch({
      type: authConstants.PROFILE_EDIT_REQUEST,
      payload: {
        isLoading: true,
      },
    });

    const { data, isError, message } = await authServiceInstance.editProfile(payload);
    if (isError) {
      dispatch({
        type: authConstants.PROFILE_EDIT_FAILED,
        payload: {
          isError: true,
          isLoading: false,
          status: message ? message.status : "",
          statusText: message ? message.statusText : "",
          errors: message ? message.data.errors : {},
        },
      });
    } else {
      dispatch({
        type: authConstants.PROFILE_EDIT_SUCCESS,
        payload: {
          isError: false,
          isLoading: false,
          data: data.results,
          status: message? message.status : "",
          responseCode: data.status.code,
          responseData: data.status.response,
          responseDesc: data.status.message,
          isSubmitted: true,
        },
      });
    }
  }
}

function activate(key) {
  return async (dispatch) => {
    dispatch({
      type: authConstants.ACTIVATE_ACCOUNT_REQUEST,
      payload: {
        isLoading: true,
      },
    });

    const { data, isError, message } = await authServiceInstance.activate(key);

    if (isError) {
      dispatch({
        type: authConstants.ACTIVATE_ACCOUNT_FAILED,
        payload: {
          isError: true,
          isLoading: false,
          status: message ? message.status : "",
          statusText: message ? message.statusText : "",
          errors: message ? message.data.errors : {},
        },
      });
    } else {
      dispatch({
        type: authConstants.ACTIVATE_ACCOUNT_SUCCESS,
        payload: {
          isError: false,
          isLoading: false,
          data: data.results,
          status: message? message.status: "",
        },
      });
    }
  }
}