export const authConstants = { 
  REGISTER_REQUEST: 'AUTH_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'AUTH_REGISTER_SUCCESS',
  REGISTER_FAILED: 'AUTH_REGISTER_FAILED',

  LOGIN_REQUEST: 'AUTH_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
  LOGIN_FAILED: 'AUTH_LOGIN_FAILED',

  LOGIN_STORE_TOKEN: 'AUTH_LOGIN_STORE_TOKEN',
  LOGIN_REMOVE_TOKEN: 'AUTH_LOGIN_REMOVE_TOKEN',

  RESET_AUTH_TOKEN: 'AUTH_TOKEN_RESET',
  CHECK_LOCAL_TOKEN: 'AUTH_CHECK_LOCAL_TOKEN',

  FORGOT_PASS_REQUEST: 'AUTH_FORGOT_PASS_REQUEST',
  FORGOT_PASS_SUCCESS: 'AUTH_FORGOT_PASS_SUCCESS',
  FORGOT_PASS_FAILED: 'AUTH_FORGOT_PASS_FAILED',

  RESET_PASS_REQUEST: 'AUTH_RESET_PASS_REQUEST',
  RESET_PASS_SUCCESS: 'AUTH_RESET_PASS_SUCCESS',
  RESET_PASS_FAILED: 'AUTH_RESET_PASS_FAILED',

  ACTIVATE_ACCOUNT_REQUEST: 'AUTH_ACTIVATE_ACCOUNT_REQUEST',
  ACTIVATE_ACCOUNT_SUCCESS: 'AUTH_ACTIVATE_ACCOUNT_SUCCESS',
  ACTIVATE_ACCOUNT_FAILED: 'AUTH_ACTIVATE_ACCOUNT_FAILED',

  PROFILE_REQUEST: 'AUTH_PROFILE_REQUEST',
  PROFILE_SUCCESS: 'AUTH_PROFILE_SUCCESS',
  PROFILE_FAILED: 'AUTH_PROFILE_FAILED',

  PROFILE_EDIT_REQUEST: 'AUTH_PROFILE_EDIT_REQUEST',
  PROFILE_EDIT_SUCCESS: 'AUTH_PROFILE_EDIT_SUCCESS',
  PROFILE_EDIT_FAILED: 'AUTH_PROFILE_EDIT_FAILED',
  
}
